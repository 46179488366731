import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router"

import SharePanel from "../components/Modal/SharePanel";
import SharedDetailsPanel from "../components/Modal/SharedDetailsPanel";
import ModalAnnotation from "../components/ModalAnnotation";
import ModalAnnotationAlert from "../components/ModalAnnotationAlert";
import ModalComment from "../components/ModalComment";
import VideoAnnotationPanel from "../components/VideoAnnotationPanel";

import * as video from "../store/video";

import { useParams } from "react-router-dom";
import DateHelper from "../helpers/date";
import {setVideo} from "../store/video";
import Layout from "./Layout";


const REQUEST_TIMER = 10000

export default function VideoAnnotationPage(){
    const [modalAnnotationShown, setModalAnnotationShown] = useState(false)
    const [modalCommentShown, setModalCommentShown] = useState(false)
    const [modalShare, setModalShare] = useState(false)
    const [modalSharedDetails, setModalSharedDetails] = useState(false)

    const [modalAnnotationAlertShown, setModalAnnotationAlertShown] = useState(false)
    const [commentParent, setCommentParent] = useState(0)
    const [commentData, setCommentData] = useState(null)
    const [annotationData, setAnnotationData] = useState(null)
    const [annotationId, setAnnotationId] = useState(-1)
    const [videoTags, setVideoTags] = useState([])
    const [isTimerShown, setIsTimerShown] = useState(false)
    const [timerSec, setTimerSec] = useState(0)

    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [startTime, setStartTime] = useState(Date.now());

    const [currentTime, setCurrentTime] = useState(-1)
    const { videoId } = useParams();
    const [videoUrl, setVideoUrl] = useState("")
    
    const { selectedVideo } = useSelector((state) => state.video);
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const { state } = useLocation();

    useEffect(() => {
        if (state !== null) {
            const { new_video } = state;

            if (new_video) setModalShare(true)
        }
    }, [state])

     useEffect(() => {
         setIsTimerShown(false)
         setStartTime(Date.now())
    }, [])


    useEffect(() => {
        dispatch(video.get(videoId))
    }, [dispatch, videoId])

    useEffect(() => {
        const timer = setTimeout(() => dispatch(video.get(videoId)), REQUEST_TIMER);

        return () => clearTimeout(timer);
    });

    const handleReply = (annotationId, parentId) => {
        setAnnotationId(annotationId)
        setCommentParent(parentId)
        setCommentData(null)
        setModalCommentShown(true) 
    }

    const handleEditComment = ( comment ) => {
        setModalCommentShown(true)
        setCommentData(comment)
    }

    const setTime = () => {
        const time = Date.now() - startTime

        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
      };

    useEffect(() => {
        /*
        const interval = setInterval(() => setTime(), 1000);

        return () => clearInterval(interval);
        */
      }, []);

    useEffect(() => {
        if ((selectedVideo !== null) && (selectedVideo.url !== null)) {
            if (selectedVideo.type === 'youtube') {
                setVideoUrl(selectedVideo.identifier)
            }
            else {
                if (videoUrl.length > 0) {
                    if (videoUrl !== selectedVideo.url) {
                        const newUrl = selectedVideo.url.split("?")
                        const prevUrl = videoUrl.split("?")
                        const expirySeconds = parseInt(videoUrl.split("Expires=")[1])
                        const expiryTime = DateHelper.epochToJsDate(expirySeconds)
                        const needRefresh = (DateHelper.getTimeBetweenDates(new Date(), expiryTime).seconds < 60)

                        if ((newUrl[0] !== prevUrl[0]) || needRefresh) setVideoUrl(selectedVideo.url)
                    }
                }
                else setVideoUrl(selectedVideo.url)
            }
        }
    }, [selectedVideo])

    const validateAnnotation = (ts) => {
        setCurrentTime(ts)
        setAnnotationId(-1)

        const isValid = selectedVideo.annotations.filter(ann => ann.time === ts).length === 0
        
        setAnnotationData(null)
        if (isValid) {
            setModalAnnotationShown(true)
            setVideoTags(selectedVideo.tags.map(t => ({ ...t, id: t.id.toString() })))
        }
        else {
            setModalAnnotationAlertShown(true)
        }
    }

    const handleEditAnnotation = (annData) => {
        setAnnotationId(-1)
        setAnnotationData(annData)
        setModalAnnotationShown(true)
    }

    const closeAnnotationModal = () => {
        setAnnotationData(null)
        setVideoTags([])
        setModalAnnotationShown(false)
    }

    const toggleTimerDisplay = (e) => {
        e.stopPropagation()
        setIsTimerShown(!isTimerShown)
    }

    return(
        <Layout className="pl-4">
            {/*
            <div className="flex flex-col justify-center items-center fixed bottom-0 right-10 bg-primaryBlue py-1 px-4 text-white rounded-t-md cursor-pointer"
                 onClick={toggleTimerDisplay}
            >
                <div className="text-xs">Click to { isTimerShown ? 'hide':'show' } timer</div>
                {
                isTimerShown &&
                <div className="text-lg">

                    {hours > 0 ? String(hours).padStart(2, '0') + ':' : ''}{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
                </div>
                }
            </div>
            */}
            {
                (selectedVideo === null) ?
                <div className="w-full pt-20 flex justify-center">Loading video</div> :
                <VideoAnnotationPanel
                    videoUrl={videoUrl}
                    data={selectedVideo}
                    openShare={() => {setModalShare(true)}}
                    openSharedDetails={() => {setModalSharedDetails(true)}}
                    handleAnnotate={validateAnnotation}
                    handleEditAnnotation={handleEditAnnotation}
                    handleReply={handleReply}
                    handleEditComment={handleEditComment}
                />
            }
            <ModalAnnotation
                show={modalAnnotationShown}
                annotationData={annotationData}
                videoId={videoId}
                videoTags={videoTags}
                ts={currentTime}
                onClose={closeAnnotationModal}
            />
            <ModalAnnotationAlert
                show={modalAnnotationAlertShown}
                ts={currentTime}
                onClose={() => { setModalAnnotationAlertShown(false) }}
            />
            <ModalComment
                show={modalCommentShown}
                videoId={videoId}
                annotationId={annotationId}
                commentData={commentData}
                commentParent={commentParent}
                onClose={() => { setModalCommentShown(false) }}
            />
            <SharePanel
                show={modalShare}
                video={selectedVideo}
                videoId={videoId}
                refreshData={() => { dispatch(video.get(videoId)) }}
                collaborators={ selectedVideo !== null ? selectedVideo.collaborator : [] }
                coachs={ selectedVideo !== null ? selectedVideo.shared_user : [] }
                onClose={() => { setModalShare(false) }}
            />

            <SharedDetailsPanel
                show={modalSharedDetails}
                video={selectedVideo}
                videoId={videoId}
                collaborators={ selectedVideo !== null ? selectedVideo.collaborator : [] }
                coachs={ selectedVideo !== null ? selectedVideo.shared_user : [] }
                onClose={() => { setModalSharedDetails(false) }}
            />
        </Layout>
    )
} 


/*
<button 
    className="ml-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bg-gray-300 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded p-1 text-sm" 
    onClick={() => {}}
>
    Go
</button>
*/