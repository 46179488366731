import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import TreeHelper from "../helpers/tree";
import VideoService from "../services/video.services";


export const list = createAsyncThunk(
    "video/list",
    async ({keywords="", sort="latest"}, thunkAPI) => {
        try {
            const response = await VideoService.list(keywords, sort);
            // thunkAPI.dispatch(setMessage(response.data.message));
            return response;
        } catch (error) {
            console.log(error)
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const get = createAsyncThunk(
    "video/get",
    async (videoId, thunkAPI) => {
        try {
            const response = await VideoService.get(videoId);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

const videoSlice = createSlice({
    name: "video",
    initialState: { 
        videos: [],
        page: 0,
        totalItems: 0,
        totalPages: 0,
        perPage: 10,
        selectedVideo: null,
        loading: false,
        loadingSingle: false,
    },
    reducers: {
        setVideo: (state, action) => {
            state.videos = action.payload.items            
            state.page =  action.payload.page
            state.perPage =  action.payload.items_per_page
            state.totalItems = action.payload.total_items
            state.totalPages = action.payload.total_pages
        },
    },
    extraReducers: {
        [list.fulfilled]: (state, action) => {
            state.videos = action.payload.items
            state.page =  action.payload.page
            state.perPage =  action.payload.items_per_page
            state.totalItems = action.payload.total_items
            state.totalPages = action.payload.total_pages
        },
        [get.pending]: (state, action) => {
            state.loadingSingle = true
        },
        [get.fulfilled]: (state, action) => {
            state.loadingSingle = false
            
            let comments = action.payload.comments
            let flatComments = action.payload.comments.slice(0)
            
            try {
                comments = TreeHelper.arrayToTree(action.payload.comments)
            }
            catch (err) {
                console.log(err)
            }

            let timeline = null
            if (action.payload.timeline.length > 0) {
                const jsonTimeline = JSON.parse(action.payload.timeline[0].state.replace(/'/g, '"'))
                timeline = jsonTimeline
            }

            action.payload.collaborator.sort((a, b) => (a.name > b.name) ? 1 : -1)

            state.selectedVideo = {
                ...action.payload,
                comments,
                flatComments,
                timeline
            }
        },
        [get.rejected]: (state, action) => {
            state.loadingSingle = false
            state.selectedVideo = null
        },
    },
});

const { reducer, actions } = videoSlice;

export const { setVideo } = actions

export default reducer;