import React from 'react';


const TIMELINE_WIDTH = 1000
const TIMELINE_HEIGHT = 80
const TIMELINE_STROKE_WIDTH = 4

class VideoTimeline extends React.Component {
    constructor (props) {
        super(props);
        
        this.canvasRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        const { annotations } = this.props;
        this.clearCanvas();

        if (annotations && annotations.length > 0) {
            for (let i=0; i < annotations.length; i++) {
                this.drawAnnotationMark(annotations[i]);
            }
        }

        let canvas = this.canvasRef.current;
        canvas.addEventListener("click", this.onClick, false);
        canvas.addEventListener("mousemove", this.onMove, false);
        // this.drawTimestamp();
    }

    updateCanvas = (newTopLeftView, newScale) => {
        // let canvas = this.canvasRef.current;
        // let ctx = canvas.getContext('2d');

        // let oriCanvas = this.oriCanvasRef.current;
        
        // ctx.setTransform(1, 0, 0, 1, 0, 0);
        // ctx.setTransform(
        //     newScale, 0, 0, 
        //     newScale, 
        //     -(newScale - 1) * canvas.width/2 - newTopLeftView.x,
        //     -(newScale - 1) * canvas.height/2 - newTopLeftView.y
        // );

        // ctx.clearRect(0, 0, this.props.width, this.props.height);
        // ctx.drawImage(oriCanvas, 0, 0, this.props.width, this.props.height);
    }

    draw = (mousePos, mouseLastPos, topLeft, scale) => {
        const width = TIMELINE_WIDTH
        const height = TIMELINE_HEIGHT;

        let canvas = this.canvasRef.current;
        let ctx = canvas.getContext('2d');
        
        let adjustedPos = {
            x: (canvas.offsetWidth * scale - canvas.offsetWidth) / 2,
            y: (canvas.offsetHeight * scale - canvas.offsetHeight) / 2,
        }
        
        // adjusted mouse position
        let lastPos = {
            x: (((mouseLastPos.x + adjustedPos.x) / canvas.offsetWidth * width) + topLeft.x) / scale,
            y: (((mouseLastPos.y + adjustedPos.y) / canvas.offsetHeight * height) + topLeft.y) / scale
        };

        let currentPos = {
            x: (((mousePos.x + adjustedPos.x) / canvas.offsetWidth * width) + topLeft.x) / scale,
            y: (((mousePos.y + adjustedPos.y) / canvas.offsetHeight * height) + topLeft.y) / scale
        };

        ctx.beginPath();
        ctx.moveTo(lastPos.x, lastPos.y);
        ctx.lineTo(currentPos.x, currentPos.y);

        ctx.strokeStyle = 'red';
        ctx.lineWidth = 4;
        ctx.stroke();
        ctx.closePath();

        this.updateCanvas(topLeft, scale);
    }

    drawTimestamp = () => {
        const { duration, ts } = this.props
        
        if (ts && duration && (duration > 0)) {
            const canvas = this.canvasRef.current;
            const ctx = canvas.getContext('2d');
            const markX = ts/duration * TIMELINE_WIDTH;
            
            ctx.beginPath();
            ctx.moveTo(markX, 0);
            ctx.lineTo(markX, TIMELINE_HEIGHT);
    
            ctx.strokeStyle = '#1177d1';
            ctx.lineWidth = TIMELINE_STROKE_WIDTH;
            ctx.stroke();
            ctx.closePath();
        }
    }

    drawAnnotationMark = (ann) => {
        const ts = ann.time
        const { duration } = this.props

        if (duration && (duration > 0)) {
            let canvas = this.canvasRef.current;
            let ctx = canvas.getContext('2d');
            const markX = ts/duration * TIMELINE_WIDTH;
            
            ctx.beginPath();
            ctx.moveTo(markX, 0);
            ctx.lineTo(markX, TIMELINE_HEIGHT);
    
            ctx.strokeStyle = '#1177d1';
            try {
                if (ann.status === 1) {
                    // Flagged
                    ctx.strokeStyle = '#EF4444';
                }
                else if (this.props.user.uuid === ann.user.uuid) {
                    ctx.strokeStyle = '#FF8000';
                }
            }
            catch (err) {

            }
            
            ctx.lineWidth = TIMELINE_STROKE_WIDTH-1;
            ctx.stroke();
            ctx.closePath();
        }
    }

    clearCanvas = () => {
        this.canvasRef.current.getContext('2d').clearRect(0, 0, TIMELINE_WIDTH, TIMELINE_HEIGHT);
    }
    
    onMove = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const { annotations } = this.props;
        const canvas = this.canvasRef.current;
        let element = this.canvasRef.current;
        let offsetX = 0

        if (element.offsetParent) {
            do {
                offsetX += element.offsetLeft;
            } while ((element = element.offsetParent));
        }

        const x = e.pageX - offsetX;
        const selectedTS = parseInt(Math.round(x/canvas.offsetWidth * this.props.duration))
        
        const targetAnn = annotations.filter(el => el.time === selectedTS)
        
        // Put your mousemove stuff here
        let newCursor = 'default'
        if (targetAnn.length > 0) newCursor = 'pointer'

        canvas.style.cursor = newCursor
    }

    onClick = (e) => {
        const canvas = this.canvasRef.current;
        let element = this.canvasRef.current;
        let offsetX = 0, offsetY = 0

        if (element.offsetParent) {
            do {
                offsetX += element.offsetLeft;
                offsetY += element.offsetTop;
            } while ((element = element.offsetParent));
        }

        const x = e.pageX - offsetX;
        // const y = e.pageY - offsetY;

        // console.log(x, y, canvas.offsetWidth, canvas.offsetHeight)
        const selectedTS = parseInt(Math.round(x/canvas.offsetWidth * this.props.duration))

        this.props.onClick && this.props.onClick(selectedTS)
	}

    render () {
        return (
            <div className="relative bg-gray-300 rounded-sm overflow-hidden max-h-20">
                <canvas
                    className="w-full"
                    ref={this.canvasRef}
                    height={TIMELINE_HEIGHT}
                    width={TIMELINE_WIDTH}
                />
            </div>
        );
    }
}

export default VideoTimeline;