import axios from "axios";
import {BASE_URL} from "../services/server";


const sendEvent = (eventData, video=null, annotation=null, comment=null) => {
    const mouse = eventData

    let data = {
        mouse,
    }

    if (video != null) data = { ...data, video }

    axios.post(
        BASE_URL + 'ladder/analytics',
        data
    ).then((res) => {
        // console.log(res)
    })
    .catch((err) => {

    })
}
const sendData = (e, video, annotation=null, comment=null) => {
    const mouse = {
        x: e.pageX,
        y: e.pageY,
        event_name: e.type,
        target_name: e.target_name,
    }

    let data = {
        video,
        mouse
    }

    if (annotation) data.annotation = annotation
    if (comment) data.comment = comment

    axios.post(
        BASE_URL + 'ladder/analytics',
        data
    ).then((res) => {
        // console.log(res)
    })
    .catch((err) => {

    })
}

const LadderAnalyticsHelper = {
    sendData,
    sendEvent
}

export default LadderAnalyticsHelper;