import {useEffect, useState} from "react";
import {Button, Input, message, Space, Switch, Table} from 'antd'
import axios from "axios";
import {BASE_URL} from "../../services/server";
import {useNavigate} from "react-router-dom";

const { Column, ColumnGroup } = Table;

const StorageManagement = (props) => {
    const [data, setData] = useState({
        total_items: 0,
        items_per_page: 10,
        page: 1,
        total_pages: 1
    })
    const [totalFiles, setTotalFiles] = useState(0)
    const [totalSize, setTotalSize] = useState(0)

    const [users, setUsers] = useState([])

    useEffect(() => {
        getStorage()
    }, [])

    const getStorage = () => {
        axios.get(BASE_URL + `video/storage`)
            .then((res) => {
                setTotalFiles(res.data.total_files)
                setTotalSize(res.data.total_size)
            })
    }

    return (
    <div className="">
        <p>Total uploaded video: { totalFiles }</p>
        <p>Total video size: {(totalSize / (1024 * 1024) ).toFixed(2) } MB</p>
    </div>
    )
}

export default StorageManagement