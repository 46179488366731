import React, { useEffect, useState } from "react";
import Input from "antd/es/input/Input";
import { Tabs } from "antd";

import Sidebar from "../components/Sidebar";

import * as video from "../store/video";
import { useDispatch, useSelector } from "react-redux";
import ManagementPanel from "../components/management"
import Layout from "./Layout";

const ADMIN_MENU = [
    {
        key: '1',
        label: `Experiment Management`,
        children: <ManagementPanel.LadderExperimentManagement />,
    },
    {
        key: '2',
        label: `Ladder Users`,
        children: <ManagementPanel.LadderUserManagement />,
    }
]
export default function Video(){
    return(
        <Layout className="px-4">
            <span className="mt-4 text-xl font-medium">Ladder Admin</span>
            <div className=" container flex flex-col min-h-screen">
                <Tabs
                    className="mt-4"
                    defaultActiveKey="1"
                    items={ADMIN_MENU}
                    onChange={() => {}}
                />
            </div>
        </Layout>
    )
} 