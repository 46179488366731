import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Bar } from "react-chartjs-2";
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
);

const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        y: {
            min: 0,
            ticks: {
                stepSize: 1,
            }
        },
        x: {
            ticks: {
                autoSkip: false,
                maxRotation: 45,
                minRotation: 45
            }
        }
    },
    plugins: {
        zoom: {
            zoom: {
                wheel: {
                    enabled: true,
                },
                pinch: {
                    enabled: true
                },
                mode: 'xy',
            }
        }
    }
};

const MyTags = (props) => {const [loading, setLoading] = useState(true)
    const { user } = useSelector((state) => state.auth);
    const [chartData, setChartData] = useState([])

    useEffect(() => {
        if ((props.data !== null) && (user !== null)) {
            let tags = {}
            let tagIds = []
            let labels = []
            let myDataValues = []
            let dataValues = []

            props.data.annotations.forEach((ann) => {
                const isMine = (ann.user.uuid === user.uuid)
                
                ann.tags.forEach((tag) => {
                    if (!tagIds.includes(tag.id)) {
                        tagIds.push(tag.id)
                        tags[tag.id] = {
                            text: tag.tag,
                            mine: isMine ? 1 : 0,
                            total: 1
                        }
                    }
                    else {
                        tags[tag.id].total++
                        tags[tag.id].mine += (isMine ? 1 : 0)
                    }
                })
            })

            props.data.flatComments.forEach((cmt) => {
                const isMine = (cmt.user.uuid === user.uuid)

                cmt.tags.forEach((tag) => {
                    if (!tagIds.includes(tag.id)) {
                        tagIds.push(tag.id)
                        tags[tag.id] = {
                            text: tag.tag,
                            mine: isMine ? 1 : 0,
                            total: 1
                        }
                    }
                    else {
                        tags[tag.id].total++
                        tags[tag.id].mine += (isMine ? 1 : 0)
                    }
                })
            })

            let sortedTags = []
            Object.entries(tags).forEach(([tId, tData]) => {
                sortedTags.push(tData)
            })
            sortedTags.sort((a, b) =>
                a.text.toLowerCase() < b.text.toLowerCase() ? -1 : 1
            )

            Object.entries(sortedTags).forEach(([_, tData]) => {
                labels.push(tData.text)
                myDataValues.push(tData.mine)
                dataValues.push(tData.total - tData.mine)
            })
            
            setChartData({
                labels,
                datasets: [
                    {
                        label: 'My Tag',
                        data: myDataValues,
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                    {
                        label: 'Others\' Tag',
                        data: dataValues,
                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    }
                ],
            })

            setLoading(false)
        }
    }, [props.data, user])

    if (loading) return <div>Loading</div>

    return(
        <div className="h-full flex-1 flex flex-col items-center justify-center">
            <h2 className="mb-2 font-bold">
                My Tags
            </h2>
            <div className="w-full px-4 flex-1">
            {
            (chartData.labels.length === 0) ?
                <span className="flex text-gray-600 mt-10">No data to display</span>
            :
                <Bar
                    data={chartData}
                    options={options}
            />
            }
            </div>
        </div>
    )
}

export default MyTags;