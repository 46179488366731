import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {Button, Modal, Image, Input, Tabs} from "antd";
import uploadModalLogo from "../assets/uploadModalLogo.svg";
import { message, Upload } from 'antd';

import { API_URL } from "../services/video.services";
import axios from "axios";

import "../assets/styles/tag.css";
import {useDisableBodyScroll} from "../hooks/BodyScroll";


const { Dragger } = Upload;

const UploadVideoModal = (props) => {
    useDisableBodyScroll(props.open);

    const [fileList, setFileList] = useState([])
    const [title, setTitle] = useState("")
    const [youtubeLink, setYoutubeLink] = useState("")
    const [description, setDescription] = useState("")
    const [uploading, setUploading] = useState(false)

    const dispatch = useDispatch();

    const resetFields = () => {
        setUploading(false)
        setTitle("")
        setDescription("")
        setFileList([])
    }

    const handleUpload = () => {
        const formData = new FormData();

        formData.append("title", title);
        formData.append("description", description);
        formData.append("duration", 0);
        formData.append("thumbnail", "");
        if (fileList.length > 0) {
            formData.append("type", "s3");
            formData.append("video", fileList[0]);
        }
        else {
            formData.append("type", "youtube");
            formData.append("url", youtubeLink);
        }
        
        setUploading(true)

        axios.post(API_URL + "/upload", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            if (res.status === 201) {
                resetFields()
                message.success('Video has been uploaded')
                props.onOk(res.data.url)
            }
        })
        .catch(() => {
            message.error('Video cannot be uploaded.');
        })
        .finally(() => {
            setUploading(false)
        });
    }

    const uploadProps = {
        name: 'file',
        accept: 'video/*',
        multiple: false,
        maxCount: 1,

        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        beforeUpload(file) {
            //setFileList([...fileList, file])
            setFileList([file])
            
            return false;
        },
        onRemove: (file) => {
            setFileList([])
            
            //   const index = state.fileList.indexOf(file);
            //   const newFileList = state.fileList.slice();
            //   newFileList.splice(index, 1);
            //   setFileList(newFileList)
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const handleCancel = () => {
        resetFields()
        props.onCancel()
    }

    const uploadItems = [
        {
            key: '1',
            label: 'Video File',
            children:
                <Dragger {...uploadProps} className="w-full">
                    <div className="w-full md:px-64 py-10">
                        <Image
                            src={uploadModalLogo}
                            width={60}
                            preview={false}
                            className="mb-4"
                        />
                        <p className="ant-upload-text text-black">Upload a File</p>
                        <p className="ant-upload-hint">
                            Click to <span className="text-primaryBlue">browse</span>, or drag & drop a file here
                        </p>
                    </div>
                </Dragger>,
        },
        {
            key: '2',
            label: 'Youtube Link',
            children:
                <div className="w-full flex flex-row px-8 align-center items-center">
                    <p className="text-lg mr-2 md:w-1/6">YouTube URL</p>
                    <Input
                        placeholder="Enter YouTube link"
                        className="flex-1 sm:w-1/2 text-base py-2 "
                        value={youtubeLink}
                        onChange={(e) => setYoutubeLink(e.currentTarget.value)}
                    />
                </div>,
        },
    ];

    return(
        <Modal 
            title="Upload a Video"
            centered
            destroyOnClose={true}
            closable={false} 
            open={props.open} 
            onOk={props.onOk} 
            onCancel={handleCancel}
            width={900}
            styles={{
                body: {height: "500px"}
            }}
            footer={[
                <>
                    <div className="flex flex-row justify-center items-center">
                        <Button key="back" className="mr-8 px-12" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button 
                            key="submit" type="primary" className="px-12 bg-primaryBlue" 
                            onClick={handleUpload}
                            disabled={
                                (
                                    ((fileList.length === 0) || (title.length === 0))
                                    && (youtubeLink.length === 0)
                                )
                            }
                            loading={uploading}
                        >
                            Upload Video
                        </Button>
                    </div>
                </>
            ]}
        >
            <>
                <div className="h-[100%] flex flex-col items-center">

                    <Tabs className="w-full mt-4 mb-8"
                          defaultActiveKey="1"
                          items={uploadItems}
                    />

                    <div className="w-full flex flex-row px-8 align-center items-center">
                        <p className="text-lg mr-2 md:w-1/6">Video Title</p>
                        <Input
                            placeholder="Enter video title"
                            className="flex-1 sm:w-1/2 text-base py-2 "
                            value={title}
                            onChange={(e) => setTitle(e.currentTarget.value)}
                        />
                    </div>
                    <div className="mt-4 w-full flex px-8 align-center items-start">
                        <p className="text-lg mr-2 md:w-1/6">Description</p>
                        <Input.TextArea
                            placeholder="Enter video description"
                            className="flex-1 sm:w-1/2 text-base py-2 "
                            rows={4}
                            value={description}
                            onChange={(e) => setDescription(e.currentTarget.value)}
                        />
                    </div>
                </div>
            </>
        </Modal>
    )
}

export default UploadVideoModal;