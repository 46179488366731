import ActiveTimeline from "./ActiveTimeline"
import DiscussionNetwork from "./DiscussionNetwork"
import MyTags from "./MyTags"
import TagTally from "./TagTally"
import LadderMyTags from "./LadderMyTags"
import LadderCloud from "./LadderCloud"
import LadderReplies from "./LadderReplies"
import LadderPosted from "./LadderPosted"

const AnalyticsCard = {
    ActiveTimeline,
    DiscussionNetwork,
    MyTags,
    TagTally,

    LadderCloud,
    LadderMyTags,
    LadderPosted,
    LadderReplies,
}

export default AnalyticsCard;