import React, {useEffect, useState} from "react";
import {Button, Image, Input, message, Modal, Popconfirm, Space, Switch, Table} from 'antd'
import axios from "axios";
import {BASE_URL} from "../../services/server";
import {useNavigate} from "react-router-dom";
import TagPanel from "../Modal/TagPanel";
import ManageSharePanel from "../Modal/ManageSharePanel";
import uploadLogo from "../../assets/uploadLogo.svg";
import UploadExperimentModal from "../ladder/UploadExperimentModal";
import uploadModalLogo from "../../assets/uploadModalLogo.svg";

const { Column, ColumnGroup } = Table;

const LadderUserManagement = (props) => {
    const [tagModal, setTagModal] = useState(false)
    const [editModalOpen, setEditModalOpen] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [experiments, setExperiments] = useState([])
    const [shownSharedPanel, setShownSharedPanel] = useState(false)
    const [sharedUsers, setSharedUsers] = useState([])
    const [selectedTags, setSelectedTags] = useState([])
    const [videoId, setVideoId] = useState(null)
    const [room, setRoom] = useState(null)
    const [selectedXp, setSelectedXp] = useState(null)
    const navigate = useNavigate();

    const [title, setTitle] = useState('')

    useEffect(() => {
        getExperiments()
    }, [])

    const getExperiments = () => {
        // axios.get(BASE_URL + 'xp-admin/videos')
        //     .then((res) => {
        //         setExperiments(res.data.items)
        //     })
    }

    const editExperiment = (e) => {
        const xpId = e.currentTarget.dataset.id
        const selectedXp = experiments.filter(xp => xp.id === parseInt(xpId))

        if (selectedXp.length > 0) {
            setSelectedXp(selectedXp[0].id)
            setTitle(selectedXp[0].title)
            setRoom(selectedXp[0].room)
        }

        setEditModalOpen(true)
    }

    const handleDelete = (targetId) => {

    }

    const openSharedPanel = (e) => {
        const targetId = e.currentTarget.dataset.id
        const selectedVideo = experiments.filter(e => (e.id === parseInt(targetId)))

        if (selectedVideo.length > 0) {
            setSharedUsers(selectedVideo[0].shared_with.sort((a, b) => (a.name > b.name ? 1 : -1)))
        }
        else setSharedUsers([])
        setShownSharedPanel(true)
    }
    const closeSharedPanel = () => {
        setShownSharedPanel(false)
        setSharedUsers([])
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleModalOk = () => {
        setIsModalOpen(false);
    };

    const handleModalCancel = () => {
        setIsModalOpen(false);
    };

    return (
    <div className="">
        <div className="w-full flex justify-between mb-4">
                <Button type="primary" htmlType="submit" className="bg-primaryBlue h-13" onClick={showModal}>
                    <div className="flex flex-row justify-center items-center px-1">
                        <Image
                            src={uploadLogo}
                            width={31}
                            preview={false}
                        />
                        <div className="pl-2 text-uploadVideoButton">Create Experiment</div>
                    </div>
                </Button>
                <Button size="small" className="bg-blue-200 px-4 py-2" onClick={getExperiments}>
                    Refresh
                </Button>
                <UploadExperimentModal
                    open={isModalOpen}
                    onOk={handleModalOk}
                    onCancel={handleModalCancel}/>
        </div>
        <Table dataSource={experiments} rowKey="uuid">
            <Column title="Research ID" dataIndex="id" key="id" />
            <Column title="Student name"
                render={(_, record) => (
                    <Space size="middle" className="hover:underline hover:cursor-pointer"
                           data-id={record.id}
                           onClick={openSharedPanel}
                    >
                        {record.participants.length}
                    </Space>
                )}
            />
            <Column
                title="Action"
                key="action"
                render={(_, record) => (
                <Space size="middle">
                    <a data-id={record.id} onClick={editExperiment}>Edit</a>
                    <Popconfirm
                        title="Delete the user"
                        description="Are you sure to delete this user?"
                        onConfirm={() => { handleDelete(record.id) }}
                        okButtonProps={{ className: "bg-activeBlue"}}
                        okText="Yes"
                        cancelText="No"
                    >
                        <a>Delete</a>
                    </Popconfirm>
                </Space>
                )}
            />
        </Table>
    </div>
    )
}

export default LadderUserManagement