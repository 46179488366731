import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { useEffect, useState } from 'react';

import { Bar } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";

const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
        },
        zoom: {
            zoom: {
                wheel: {
                    enabled: true,
                },
                pinch: {
                    enabled: true
                },
                mode: 'xy',
            }
        }
    },
    scales: {
        y: {
            min: 0,
            ticks: {
                stepSize: 1,
            }
        },
        x: {
            ticks: {
                autoSkip: false,
                maxRotation: 45,
                minRotation: 45
            }
        }
    },
};

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
);

const TagTally = (props) => {
    const [loading, setLoading] = useState(true)
    const [chartData, setChartData] = useState([])

    useEffect(() => {
        if (props.data !== null) {
            let tags = {}
            let tagIds = []
            let labels = []
            let dataValues = []

            props.data.annotations.forEach((ann) => {
                ann.tags.forEach((tag) => {
                    if (!tagIds.includes(tag.id)) {
                        tagIds.push(tag.id)
                        tags[tag.id] = {
                            text: tag.tag,
                            total: 1
                        }
                    }
                    else {
                        tags[tag.id].total++
                    }
                })
            })

            props.data.flatComments.forEach((cmt) => {
                cmt.tags.forEach((tag) => {
                    if (!tagIds.includes(tag.id)) {
                        tagIds.push(tag.id)
                        tags[tag.id] = {
                            text: tag.tag,
                            total: 1
                        }
                    }
                    else {
                        tags[tag.id].total++
                    }
                })
            })

            let sortedTags = []
            Object.entries(tags).forEach(([tId, tData]) => {
                sortedTags.push(tData)
            })
            sortedTags.sort((a, b) =>
                a.text.toLowerCase() < b.text.toLowerCase() ? -1 : 1
            )

            Object.entries(sortedTags).forEach(([_, tData]) => {
                labels.push(tData.text)
                dataValues.push(tData.total)
            })

            setChartData({
                labels,
                datasets: [
                    {
                        label: "Tag",
                        data: dataValues,
                        // backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        backgroundColor: 'rgba(75,192,192,0.4)',
                        borderColor: 'rgba(75,192,192,0.8)',
                        borderWidth: 1,
                        hoverBackgroundColor: 'rgba(75,192,192,0.8)',
                        hoverBorderColor: 'rgba(75,192,192,1)',
                    }
                ],
            })

            setLoading(false)
        }
    }, [props.data])

    if (loading) return <div>Loading</div>

    return(
        <div className="h-full flex-1 flex flex-col items-center justify-center">
            <h2 className="mb-2 font-bold">
                Tag Tally
            </h2>
            <div className="w-full px-4 flex-1">
            {
            (chartData.labels.length === 0) ?
                <span className="flex text-gray-600 mt-10">No data to display</span>
            :
            <Bar
                data={chartData}
                options={options}
            />
            }
            </div>
        </div>
    )
}

export default TagTally;