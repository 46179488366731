import { useEffect, useState } from 'react';
import Cytoscape from "cytoscape";
import COSEBilkent from "cytoscape-cose-bilkent";
import CytoscapeComponent from 'react-cytoscapejs';
import {Popover} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";

Cytoscape.use(COSEBilkent);

const DiscussionNetwork = (props) => {
    const [ready, setReady] = useState(false)
    const [chartElements, setChartElements] = useState([])
    const LegendText =
        <div className="text-sm ml-4 max-w-xs">
        <ul className="list-disc">
            <li>The network map is based on your/the participants' replies to one another's
        annotations</li>
            <li>Nodes of participants who have replied to one another are connected by lines</li>
            <li>Arrows point in the direction of replies</li>
            <li>Thicker lines mean more replies between nodes</li>
            <li>The more you post, the bigger your node</li>
            <li>The more you reply, the darker your node</li>
            <li>Your node will show as a grey square until you make a post</li>
            <li>Your node will be a skewed rectangle if you have only posted replies</li>
        </ul>
        </div>

    const getNodeSize = (totalAnn) => {
        return 5 + (2 * totalAnn)

        // if (totalAnn > 20) {
        //     return 40
        // }
        // else if (totalAnn > 10) {
        //     return 30
        // }
        // else if (totalAnn > 5) {
        //     return 24
        // }
        // else if (totalAnn > 3) {
        //     return 20
        // }
        // else {
        //     return 15
        // }
    }

    const getNodeColor = (totalAnn) => {
        let color = "rgb(53, 162, 235)"

        if (totalAnn > 20) {
            color = "rgb(53, 62, 85)"
        }
        else if (totalAnn > 10) {
            color = "rgb(53, 62, 100)"
        }
        else if (totalAnn > 5) {
            color = "rgb(53, 62, 155)"
        }
        else if (totalAnn > 3) {
            color = "rgb(53, 62, 200)"
        }
        else {
            color = "rgb(53, 62, 255)"
        }

        return color
    }

    useEffect(() => {
        if (props.data !== null) {
            let userIds = []
            let users = {}
            let chartEl = []

            props.data.annotations.forEach((ann) => {
                if (!userIds.includes(ann.user.uuid)) {
                    userIds.push(ann.user.uuid)
                    users[ann.user.uuid] = {
                        ...ann.user,
                        annotations: [ann.id],
                        comments: [],
                        relIds: [],
                        rels: {}
                    }
                }
                else {
                    users[ann.user.uuid].annotations.push(ann.id)
                }
            })

            props.data.flatComments.forEach((cmt) => {
                if (!userIds.includes(cmt.user.uuid)) {
                    userIds.push(cmt.user.uuid)
                    users[cmt.user.uuid] = {
                        ...cmt.user,
                        annotations: [],
                        comments: [cmt.id],
                        relIds: [],
                        rels: {}
                    }
                }
                else {
                    users[cmt.user.uuid].comments.push(cmt.id)
                }
            })

            Object.entries(users).forEach(([uuid, userData]) => {
                chartEl.push({
                    data: {
                        id: uuid, 
                        label: userData.name, 
                        type: (userData.annotations.length > 0) ? "dot" : "rhomboid",
                        size: getNodeSize(userData.annotations.length > 1 ?  userData.annotations.length : 1),
                        color: getNodeColor(userData.comments.length)
                    },
                })
            })

            let initialOtherX = 0;
            props.data.collaborator.forEach((clb) => {
                if (!userIds.includes(clb.uuid)) {
                    chartEl.push({
                        data: {
                            id: clb.uuid,
                            label: clb.name,
                            type: "rectangle",
                            size: getNodeSize(1),
                            color: 'rgb(90, 90, 90)',
                        },
                    })
                }
            })

            props.data.flatComments.forEach((cmt) => {
                Object.entries(users).forEach(([uuid, userData]) => {
                    if (uuid !== cmt.user.uuid) {
                        if (userData.annotations.includes(cmt.annotation_id)) {
                            if (users[cmt.user.uuid].relIds.includes(uuid)) {
                                users[cmt.user.uuid].rels[uuid]++
                            }
                            else {
                                users[cmt.user.uuid].relIds.push(uuid)
                                users[cmt.user.uuid].rels[uuid] = 1
                            }
                        }

                        if (userData.comments.includes(cmt.id)) {
                            if (users[cmt.user.uuid].relIds.includes(uuid)) {
                                users[cmt.user.uuid].rels[uuid]++
                            }
                            else {
                                users[cmt.user.uuid].relIds.push(uuid)
                                users[cmt.user.uuid].rels[uuid] = 1
                            }
                        }
                    }
                })
            })

            Object.entries(users).forEach(([uuid, userData]) => {
                userData.relIds.forEach(relPair => {
                    chartEl.push({
                        data: {
                            source: uuid,
                            target: relPair,
                            arrow: "triangle",
                            width: userData.rels[relPair],
                        }
                    })
                })
            })

            setChartElements(chartEl)
        }
        
    }, [props.data])

    useEffect(() => {
        if (chartElements.length > 0) {
            setReady(true)
        }
    }, [chartElements])

    return(
        <div className="h-full flex-1 flex flex-col items-center justify-center">
            <h2 className="w-full relative mb-2">
                <span className="flex w-full font-bold justify-center">
                    Our CoVAAPD Discussion Network
                </span>
                <span className="absolute right-2 top-0">
                    <Popover placement="bottomRight" title="Legend" content={LegendText}>
                        <InfoCircleOutlined className="text-activeBlue" />
                    </Popover>
                </span>
            </h2>

            <div className="flex-1 w-full">
            {
            (chartElements.length === 0) ?
                <span className="flex justify-center text-gray-600 my-4">No data to display</span>
            :
                ready &&
                <CytoscapeComponent
                    elements={chartElements}
                    layout={{
                        name: 'cose-bilkent',
                        "fit": false,
                    }}
                    zoom={1}
                    pan={{
                        x: 150,
                        y: 40,
                    }}
                    style={{ width: '100%', height: '100%' }}
                    stylesheet={
                        [{
                            "selector": "node",
                            "style": {
                                "text-valign": "top",
                                "text-halign": "center",

                            }
                        }, {
                            "selector": "node[color]",
                            "style": {
                                "background-color": "data(color)",
                            }
                        }, {
                            "selector": "node[label]",
                            "style": {
                                "label": "data(label)",
                            }
                        },  {
                            "selector": "node[size]",
                            "style": {
                                "height": "data(size)",
                                "width": "data(size)"
                            }
                        }, {
                            "selector": "node[type]",
                            "style": {
                                "shape": "data(type)",
                            }
                        }, {
                            "selector": "edge",
                            "style": {
                                "curve-style": "straight"
                            }
                        },
                        {
                            "selector": "edge[width]",
                            "style": {
                                "width": "data(width)",
                            },
                        }, {
                            "selector": "edge[arrow]",
                            "style": {
                                "target-arrow-shape": "data(arrow)"
                            }
                        }, {
                            "selector": "edge.hollow",
                            "style": {
                                "target-arrow-fill": "hollow"
                            }
                        }]
                    }
                />
            }
            </div>
        </div>
    )
}

export default DiscussionNetwork;