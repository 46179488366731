import React, { useState } from "react"
import {Image, Button, Popconfirm, message, Modal, Input} from "antd"
import Icon, { EditOutlined, DeleteOutlined } from "@ant-design/icons"

import { Link } from "react-router-dom"
import DateHelper from "../../helpers/date"
import { trimString } from "../../helpers/string"
import { useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL } from "../../services/server";

const MAX_TITLE_LEN = 65;
const MAX_DESC_LEN = 80;

const AnalyticsSvg = () => (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.6526 15.5162C11.6266 15.5162 11.598 15.5162 11.572 15.5136C11.2158 15.4824 10.9116 15.2432 10.792 14.9052L8.97463 9.6558L8.18163 11.3276C8.03083 11.6448 7.71104 11.8476 7.36003 11.8476H4.72104C4.21924 11.8476 3.81104 11.4394 3.81104 10.9376C3.81104 10.4358 4.21924 10.0276 4.72104 10.0276H6.78544L8.28823 6.866C8.44684 6.5332 8.78743 6.3304 9.15923 6.3486C9.52843 6.3694 9.84823 6.6112 9.96783 6.9596L11.8502 12.3988L12.9266 10.4852C13.0878 10.1992 13.392 10.0224 13.7196 10.0224H16.421C16.9228 10.0224 17.331 10.4306 17.331 10.9324C17.331 11.4342 16.9228 11.8424 16.421 11.8424H14.25L12.443 15.0534C12.2818 15.342 11.9776 15.5188 11.65 15.5188L11.6526 15.5162Z" fill="white"/>
        <path d="M17.8276 20.9528H3.78761C2.13921 20.9528 0.797607 19.6112 0.797607 17.9628V3.9228C0.797607 2.2744 2.13921 0.9328 3.78761 0.9328H17.8276C19.476 0.9328 20.8176 2.2744 20.8176 3.9228V17.9628C20.8176 19.6112 19.476 20.9528 17.8276 20.9528ZM3.78761 2.7528C3.14281 2.7528 2.61761 3.278 2.61761 3.9228V17.9628C2.61761 18.6076 3.14281 19.1328 3.78761 19.1328H17.8276C18.4724 19.1328 18.9976 18.6076 18.9976 17.9628V3.9228C18.9976 3.278 18.4724 2.7528 17.8276 2.7528H3.78761Z" fill="white"/>
    </svg>
);
const AnalyticsIcon = (props) => <Icon component={AnalyticsSvg} {...props} />;

const VideoCard = (props) => {
    const { user } = useSelector((state) => state.auth);
    const [editModalOpen, setEditModalOpen] = useState(false)
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const videoData = props.videoData;

    const submitEditedData = () => {
        axios.patch(
            BASE_URL + 'video/' + videoData.id,
            {
                title,
                description
            }
        ).then(res => {
            message.success('Video has been updated')
            props.refreshData && props.refreshData()
            setEditModalOpen(false)
        })
        .catch((err) => {
            message.error('Video cannot be edited')
        })
    }

    const handleDelete = (videoId) => {
        axios.delete(
            BASE_URL + 'video/' + videoId
        ).then((res) => {
            message.success('Video has been deleted')
            props.refreshData && props.refreshData()
        })
        .catch((err) => {
            message.error('Video cannot be deleted')
        })
    }

    const openModal = () => {
        setTitle(videoData.title)
        setDescription(videoData.description)
        setEditModalOpen(true)
    }

    return(
        <div className="flex flex-col bg-primaryBackground max-w-sm overflow-hidden">
            <div className="flex relative">
                <Link to={`/video/${videoData.id}`} className="w-full">
                    <Image
                            width="100%"
                            src={
                                videoData.thumbnail !== "" ? videoData.thumbnail : "/img/video.jpg"
                            }
                            preview={false}
                            className="overflow-hidden max-h-40 object-cover"
                    />
                </Link>
                {
                (videoData.user.uuid === user.uuid) &&
                <div className="flex align-end items-end absolute w-22 top-2 right-2">
                    <Button type="primary" className="mr-2 bg-black bg-opacity-25 w-8" size="medium"
                            onClick={openModal}
                    >
                        <div className="flex justify-center items-center">
                            <EditOutlined />
                        </div>
                    </Button>
                    <Popconfirm
                        title="Delete the video"
                        description="Are you sure to delete this video?"
                        onConfirm={() => { handleDelete(videoData.id) }}
                        okButtonProps={{ className: "bg-activeBlue"}}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary" className="bg-black bg-opacity-25 w-8" size="medium">
                            <div className="flex justify-center items-center">
                                <DeleteOutlined />
                            </div>
                        </Button>
                    </Popconfirm>
                </div>
                }
                <Link to={`/analytics/${videoData.id}`}>
                    <Button type="primary" className="absolute top-2 left-2 bg-black bg-opacity-25 w-10" size={"large"}>
                        <div className="flex justify-center items-center">
                            <AnalyticsIcon/>
                        </div>
                    </Button>
                </Link>

                {
                videoData.type === 'youtube' &&
                <div className="w-full absolute bottom-0 flex p-1 text-xs text-white bg-gray-900">
                    Content is from an external source.
                </div>
                }
            </div>
            <div className="mt-2 font-medium text-md">
                {  trimString(videoData.title, MAX_TITLE_LEN)}
            </div>
            <div className="font-normal text-videoCardDate text-sm leading-4">
                Uploaded at { DateHelper.getDateString(videoData.created_at_iso8601) }
                &nbsp;by <span className="text-gray-800">{videoData.user.name}</span>
            </div>

            <div className="my-2 text-gray-800 text-sm h-12">
                {
                    trimString(videoData.description, MAX_DESC_LEN)
                }
            </div>

            <Modal
                title="Edit Video"
                centered
                open={editModalOpen}
                onOk={() => submitEditedData()}
                onCancel={() => setEditModalOpen(false)}
                okButtonProps={{ className: "bg-activeBlue"}}
                width="60%"
            >
                <div className="w-full flex flex-row px-2 align-center items-center">
                    <p className="mr-1 sm:w-1/4 lg:w-1/6">Video Title</p>
                    <Input
                        placeholder="Enter video title"
                        className="flex-1 sm:w-1/2 text-base py-2 "
                        value={title}
                        onChange={(e) => setTitle(e.currentTarget.value)}
                    />
                </div>
                <div className="mt-4 w-full flex px-2 align-center items-start">
                    <p className="mr-1 sm:w-1/4 lg:w-1/6">Description</p>
                    <Input.TextArea
                        placeholder="Enter video description"
                        className="flex-1 sm:w-1/2 text-base py-2 "
                        rows={4}
                        value={description}
                        onChange={(e) => setDescription(e.currentTarget.value)}
                    />
                </div>
            </Modal>
        </div>
    )
}

// <div className="mt-2 font-normal text-videoCardDate">{`${videoData.date}`}</div>

export default VideoCard;