import VideoCard from "./VideoCard"

const ListMyVideo = (props) => {
    const { videos } = props
    return (
    <div className="grid md:grid-cols-2 xl:grid-cols-4 auto-rows-max grid-cols-1 xl:gap-4 gap-2 xl:w-270 overflow-auto">
            {videos.map(video => {
                return <VideoCard 
                    key={video.uuid}
                    videoData={video}
                    refreshData={props.refreshData}
                />
            })}
    </div>
    
    )
}

export default ListMyVideo