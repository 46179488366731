import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import ReactWordcloud from 'react-wordcloud';
import {Button, Popover, Select, Table} from "antd";

import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import LadderAnalyticsHelper from "../../helpers/analytics";

const LadderCloud = (props) => {const [loading, setLoading] = useState(true)
    const { user } = useSelector((state) => state.auth);
    const [chartData, setChartData] = useState([])
    const [max, setMax] = useState(100);
    const [dashboardType, setDashboardType] = useState(0)
    const [representation, setRepresentation] = useState('cloud')

    const LegendText =
        <div className="text-sm mx-2 max-w-xs">
            <p>What are the concepts and vocabulary you have used?</p>
            <p className="mt-2">The bigger the word, the more you have used it.</p>
            <p className="mt-2">The more words you use, the more you learn.</p>
        </div>

    const LegendTextPeer =
        <div className="text-sm mx-2 max-w-xs">
            <p>What are the concepts and vocabulary you have used?</p>
            <p className="mt-2">The bigger the word, the more you have used it.</p>
            <p className="mt-2">The more words you use, the more you learn.</p>
            <p className="mt-2">Purple: words you used</p>
            <p className="">Pink: words classmates used</p>
            <p className="">Black: words classmates and you used</p>
        </div>

    useEffect(() => {
        setDashboardType(props.dashboard ? props.dashboard: 0)
    }, [props.dashboard])

    useEffect(() => {
        if ((props.data !== null) && (user !== null)) {
            let cloudWords = {}

            if ((dashboardType !== 0) && (dashboardType !== 2) && (dashboardType !== 4)) {
                const commonWords = props.data.words.common

                cloudWords = props.data.words.mine.map(items => {
                    if (commonWords.includes(items.text)) return ({ ...items, owner: 'common'})
                    else return ({ ...items, owner: 'mine'})
                })

                let cloudWordsOther = props.data.words.others.filter(items => !commonWords.includes(items.text))

                cloudWords = cloudWords.concat(
                    cloudWordsOther.map(items => ({...items, owner: 'others'}))
                )
            }
            else cloudWords = props.data.words.mine.map(items => ({ ...items, owner: 'mine'}))

            const wordCount = cloudWords.map((r) => {
              return r.value;
            });

            setMax(Math.max(...wordCount));
            setChartData(cloudWords)

            setLoading(false)
        }
    }, [props.data])

    const callbacks = useMemo(() => {
            return {
                getWordColor: word => {
                    console.log()
                    return (
                        dashboardType !== 1 ? "#9933ff" :
                            word.owner === "mine" ? "#9933ff" : word.owner === "others" ? "#d473d4" : "#000000"
                    )
                },
                // onWordClick: console.log,
                // onWordMouseOver: console.log,
                // getWordTooltip: word => `${word.text} (${word.value}) [${word.value > 50 ? "good" : "bad"}]`,
            }
    }, [dashboardType])

    const defaultFontSize = 14
    const options = {
        rotations: 0,
        rotationAngles: [-90, 90],
        fontSizes: [defaultFontSize, max*defaultFontSize],
        padding: 1,
        scale: 'linear',
        layout: 'archimedean',
    }

    const createList = () => {
        const wordList = chartData
        const sortedData = [...wordList].sort((a, b) => a.value > b.value ? -1 : 1)
        const columns = [
            {
                title: 'Word',
                dataIndex: 'text',
                key: 'text',
            },
            {
                title: 'Number of times used',
                dataIndex: 'value',
                key: 'value',
            },
        ]

        return (
            <div className='flex max-h-60 overflow-y-scroll'>
                <Table dataSource={sortedData} columns={columns} />
            </div>
        )
    }
    const onDashboardChange = (value) => {
        let eventData = {
            type: 'Dashboard_Toggle',
        }

        const video_data = {
            id: props.data.id,
            title: props.data.title,
            dashboard: 3,
            target_chart: value
        }

        LadderAnalyticsHelper.sendEvent(eventData, video_data)

        setRepresentation(value)
    }
    if (loading) return <div>Loading</div>

    return(
        <div className="h-full flex-1 flex flex-col items-center justify-center"
             onMouseEnter={props.onMouseEnter}
             onMouseLeave={props.onMouseLeave}
        >
            <h2 className="mb-2 font-bold">
                {props.title}
                <Popover
                    placement="bottomRight"
                    content={(dashboardType !== 1) ? LegendText : LegendTextPeer}
                    className="mx-1 cursor-default">
                    <Button
                         onMouseEnter={props.onInfoMouseEnter}
                         onMouseLeave={props.onInfoMouseLeave}
                         className="bg-activeBlue text-white hover:bg-blue-200">
                        Info
                    </Button>
                </Popover>
            </h2>
            {
                ((dashboardType === 3) || (dashboardType === 5)) &&
                <Select
                    defaultValue="cloud"
                    style={{
                        width: 140,
                    }}
                    onChange={onDashboardChange}
                    options={[
                    {
                        value: 'cloud',
                        label: 'Word Cloud',
                    },
                    {
                        value: 'list',
                        label: 'List',
                    },
                    ]}
                />
            }
            <div className="w-full px-4 flex-1 overflow-hidden mt-1">
            {
            (chartData.length === 0) ?
                <span className="flex text-gray-600 mt-10">No data to display</span>
            :
                ((dashboardType === 3) || (dashboardType === 5))
                && (representation == 'list') ?
                <>
                    {createList()}
                </>
                :
                <div style={{height: "100%", width: "100%"}}>
                    <ReactWordcloud
                        callbacks={callbacks}
                        options={options}
                        words={chartData}
                    />
                </div>
            }
            </div>
        </div>
    )
}

export default LadderCloud;