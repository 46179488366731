import { FlagFilled, FlagOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import { useEffect, useState } from 'react';
import CommentBox from './CommentBox'
import DateHelper from '../helpers/date'
import { useSelector } from 'react-redux';

const CommentPanel = (props) => {
    const { user } = useSelector((state) => state.auth);
    const [comments, setComments] = useState([])

    useEffect(() => {
        let sortedComments = props.data.comments.slice(0)
        let commentTree = sortedComments.filter((cmt) => cmt.parent_id === null)
        setComments(commentTree)
    }, [props.data])

    const getTimeString = (ts) => {
        const mm = parseInt(Math.floor(ts / 60));
        const ss = ts % 60;

        return mm.toString().padStart(2, '0') + ":" + ss.toString().padStart(2, '0')
    }

    const handleFlag = (e) => {
        props.handleFlag && props.handleFlag(e, props.data.id, props.data.status === 0)
    }

    const handleReply = (e) => {
        handleReplyComment(e, 0)
    }

    const handleReplyComment = (e, parentId) => {
        props.handleReply && props.handleReply(e, props.data.id, parentId)
    }

    const handleDelete = (e) => {
        props.handleDelete && props.handleDelete(e, props.data.id)
    }

    const handleDeleteComment = (e, commentId) => {
        props.handleDeleteComment && props.handleDeleteComment(e, commentId)
    }

    const showComments = (e) => {
        props.showComments && props.showComments(props.data.id)
    }

    const handleTimeClick = (e) => {
        props.handleTimeClick && props.handleTimeClick(e, props.data)
    }

    return (
        <div className="flex flex-col md:mx-auto w-full mb-6 py-6">
            <div className="flex flex-col bg-white p-2 rounded-sm">
                <small className="text-sm font-medium text-primaryBlue cursor-pointer" onClick={handleTimeClick}>
                    { getTimeString(props.data.time) }
                </small>
                <div className="flex items-start w-full mt-4">
                    <img
                        className={"w-10 h-10 rounded-full object-cover mr-3 shadow bg-gray-200 " +
                            (props.data.user.profile_pic.length > 0 ? "p-0" : "p-2")
                        }
                        src={props.data.user.profile_pic.length > 0 ? props.data.user.profile_pic : "/img/user.png"}
                    />
                    <div className="w-full">
                        <div className="flex items-start justify-between">
                            <div className="flex flex-col">
                                {
                                props.data.tags.length > 0 &&
                                <div className="text-gray-800 text-sm font-medium mb-2">
                                    {
                                        props.data.tags.map((tag, idx) => (
                                            <span key={idx} className={idx === 0? "":"ml-1"}>#{tag.tag}</span>
                                        ))
                                    }
                                </div>
                                }
                                <h2 className="text-md font-semibold text-gray-900">{ props.data.user.name }</h2>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <small className="text-sm text-videoCardDate">Posted at { DateHelper.getDateString(props.data.created_at_iso8601) }</small>
                        </div>
                        <p className="mt-3 text-gray-700 text-sm">
                            { props.data.description }
                            { 
                            (props.data.created_at_iso8601 !== props.data.updated_at_iso8601) &&
                            <span className="ml-1 text-gray-400">(edited)</span>
                            }
                        </p>
                        <div className="mt-4 flex items-center">
                            
                            <div className={"cursor-pointer mr-4 -mt-3" + 
                                (props.data.status === 1 ? " text-red-500" : "")}
                                onClick={handleFlag}
                            >
                                { props.data.status === 0 ? <FlagOutlined /> : <FlagFilled /> }
                            </div>
                            <div className="flex mr-2 text-gray-700 text-sm mr-4">
                                <span className="cursor-pointer hover:text-activeBlue"
                                    onClick={handleReply}
                                >Reply</span>
                            </div>
                            {
                            (props.data.user.uuid === user.uuid) &&
                            <>
                            <div 
                                className="mr-2 text-sm text-gray-700  cursor-pointer hover:text-activeBlue"
                                onClick={() => { props.handleEditAnnotation(props.data) }}
                            >
                                Edit
                            </div>
                            <Popconfirm
                                title="Delete the annotation"
                                description="Are you sure to delete this annotation? All replies will also be deleted."
                                onConfirm={handleDelete}
                                okButtonProps={{ className: "bg-activeBlue"}}
                                okText="Yes"
                                cancelText="No"
                            >
                                <div className="text-sm text-gray-700 cursor-pointer hover:text-activeBlue"
                                >
                                    Delete
                                </div>
                            </Popconfirm>
                            </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-4">
                <span className="font-medium">Comments</span>
                {
                    comments.map((cmt, idx) => (
                        <CommentBox 
                            key={idx}
                            data={cmt}
                            handleReply={handleReplyComment}
                            handleDelete={handleDeleteComment}
                            handleEdit={props.handleEditComment}
                        />
                    ))
                }
            </div>
        </div>
    )
}

export default CommentPanel;