import React, {useEffect, useState} from "react";
import {Button, Image, Input, List, message, Modal, Popconfirm, Space, Table} from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';

import axios from "axios";
import {BASE_URL} from "../../services/server";
import {Link, useNavigate} from "react-router-dom";
import TagPanel from "../Modal/TagPanel";
import ManageSharePanel from "../Modal/ManageSharePanel";
import uploadLogo from "../../assets/uploadLogo.svg";
import UploadExperimentModal from "../ladder/UploadExperimentModal";
import uploadModalLogo from "../../assets/uploadModalLogo.svg";

const { Column, ColumnGroup } = Table;

const UPLOAD_TEMPLATE = '/doc/experiment-template.xlsx'

const LadderExperimentManagement = (props) => {
    const [tagModal, setTagModal] = useState(false)
    const [editModalOpen, setEditModalOpen] = useState(false)
    const [participantModalOpen, setParticipantModalOpen] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [experiments, setExperiments] = useState([])
    const [shownSharedPanel, setShownSharedPanel] = useState(false)
    const [sharedUsers, setSharedUsers] = useState([])
    const [selectedTags, setSelectedTags] = useState([])
    const [videoId, setVideoId] = useState(null)
    const [room, setRoom] = useState(null)
    const [selectedXp, setSelectedXp] = useState(null)
    const navigate = useNavigate();

    const [title, setTitle] = useState('')
    const [selectedParticipants, setSelectedParticipants] = useState([])

    useEffect(() => {
        getExperiments()
    }, [])

    const getExperiments = () => {
        axios.get(BASE_URL + 'xp-admin/videos')
            .then((res) => {
                let expData = res.data.items

                for (let i=0; i < expData.length; i++) {
                    let d1 = 0,
                        d2 = 0,
                        d3 = 0,
                        d4 = 0,
                        d5 = 0,
                        d6 = 0

                    for (let j=0; j < expData[i].participants.length; j++) {
                        if (expData[i].participants[j].id.length > 0) {
                            switch (expData[i].participants[j].id[0])
                            {
                                case '0':
                                    d1++
                                    break;
                                case '1':
                                    d2++
                                    break;
                                case '2':
                                    d3++
                                    break;
                                case '3':
                                    d4++
                                    break;
                                case '4':
                                    d5++
                                    break;
                                case '5':
                                    d6++
                                    break;
                                default:
                                    break;
                            }
                        }
                    }

                    expData[i].d1 = d1
                    expData[i].d2 = d2
                    expData[i].d3 = d3
                    expData[i].d4 = d4
                    expData[i].d5 = d5
                    expData[i].d6 = d6
                }

                setExperiments(expData)
            })
    }

    const createUsers = (e) => {
        const xp_id = e.currentTarget.dataset.id
        axios({
            url: BASE_URL + 'xp-admin/experiments/' + xp_id.toString() + '/users',
            method: 'POST',
        }).then(resp => {
        });
    }

    const removeUnusedUsers = (xpId) => {
        axios({
            url: BASE_URL + 'xp-admin/experiments/' + xpId.toString() + '/users',
            method: 'DELETE',
        }).then(resp => {
            message.success('Unused user(s) has been unlinked.')
            getExperiments()
        })
        .catch((err) => {
            message.error('User(s) could not be unlinked')
        })
    }

    const downloadData = (e) => {
        const xp_id = e.currentTarget.dataset.id
        console.log('123')
        axios({
            url: BASE_URL + 'xp-admin/download/' + xp_id.toString(),
            method: 'GET',
            responseType: 'blob',
        }).then(resp => {
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement('a');
            const filename = resp.headers["content-disposition"].split('filename="')[1].split('";')[0]

            link.href = url;
            link.setAttribute('download', filename);

            document.body.appendChild(link);
            link.click();
        });
    }

    const switchVideoTimer = (checked, e) => {
        const targetId = parseInt(e.currentTarget.dataset.id)

        axios.post(
            BASE_URL + "video/manage",
            {
                id: targetId,
                timer: checked
            }
        )
        .then((resp) => {
            message.success('Video timer has been updated.')
        })
        .catch((err) => {
            message.error('Video timer could not be saved')
        })
    }

    const manageVideoTag = (e) => {
        const targetId = parseInt(e.currentTarget.dataset.id)
        setVideoId(e.currentTarget.dataset.id)

        const selectedVideo = experiments.filter(v => v.id === targetId)
        if (selectedVideo.length > 0) {
            setSelectedTags(selectedVideo[0].tags)
        }
        else setSelectedTags([])

        setTagModal(true)
    }

    const submitEditedData = () => {
        axios.patch(
            BASE_URL + 'xp-admin/' + selectedXp,
            {
                title,
                room
            }
        ).then(res => {
            message.success('Video has been updated')

            setEditModalOpen(false)
            setSelectedXp(-1)
            setTitle('')
            setRoom('')

            getExperiments()
        })
        .catch((err) => {
            message.error('Video cannot be edited')
        })
    }

    const closeModal = () => {
        setTagModal(false)
        setVideoId(null)
    }

    const openVideo = (e) => {
        const videoUrl = '/video/' + e.currentTarget.dataset.id
        navigate(videoUrl, { state: { new_video: false } });
    }

    const openDashboard = (e) => {
        let experimentId = parseInt(e.currentTarget.dataset.id)
        let dashboardType = parseInt(e.currentTarget.dataset.dashboard)

        const dashboardUrl = '/ladder/dashboard/1'
        navigate(dashboardUrl, {
            state: {
                dashboard: dashboardType ? dashboardType : 0,
                experiment: experimentId,
                src: '/ladder-admin/',
            }
        });
    }
    const editExperiment = (e) => {
        const xpId = e.currentTarget.dataset.id
        const selectedXp = experiments.filter(xp => xp.id === parseInt(xpId))

        if (selectedXp.length > 0) {
            setSelectedXp(selectedXp[0].id)
            setTitle(selectedXp[0].title)
            setRoom(selectedXp[0].room)
        }

        setEditModalOpen(true)
    }

    const handleDelete = (targetId) => {
        axios.delete(
            BASE_URL + 'xp-admin/' + targetId,
        )
        .then((resp) => {
            message.success('Experiment has been deleted.')
            getExperiments()
        })
        .catch((err) => {
            message.error('Experiment could not be deleted')
        })
    }

    const openParticipantPanel = (e) => {
        const targetId = e.currentTarget.dataset.id
        const userDashboard = e.currentTarget.dataset.dashboard

        const selectedExperiment = experiments.filter(exp => (exp.id === parseInt(targetId)))

        if (selectedExperiment.length > 0) {
            const participants = selectedExperiment[0].participants

            if (parseInt(userDashboard) !== -1) {
                const filteredParticipants = participants.filter(part => part.id[0] === userDashboard)
                setSelectedParticipants(filteredParticipants.sort((a, b) => (a.id > b.id ? 1 : -1)))
            }
            else setSelectedParticipants(participants.sort((a, b) => (a.id > b.id ? 1 : -1)))
        }
        else setSelectedParticipants([])

        setParticipantModalOpen(true)
    }
    const closeSharedPanel = () => {
        setShownSharedPanel(false)
        setSharedUsers([])
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleModalOk = () => {
        getExperiments()
        setIsModalOpen(false);
    };

    const handleModalCancel = () => {
        setIsModalOpen(false);
    };

    return (
    <div className="">
        <div className="w-full flex justify-between mb-4">
                <Button type="primary" htmlType="submit" className="bg-primaryBlue h-13" onClick={showModal}>
                    <div className="flex flex-row justify-center items-center px-1">
                        <Image
                            src={uploadLogo}
                            width={31}
                            preview={false}
                        />
                        <div className="pl-2 text-uploadVideoButton">Create Experiment</div>
                    </div>
                </Button>
                <Button size="small" className="bg-blue-200 px-4 py-2" onClick={getExperiments}>
                    Refresh
                </Button>
                <UploadExperimentModal
                    open={isModalOpen}
                    onOk={handleModalOk}
                    onCancel={handleModalCancel}/>
        </div>
        <div>
             <Link to={UPLOAD_TEMPLATE} target="_blank" rel="noopener noreferrer"
                  className="relative inline-flex w-fit mr-6 underline"
            >
                 Download Template File
             </Link>
        </div>
        <Table dataSource={experiments} rowKey="uuid">
            <Column title="Experiment" dataIndex="title" key="title" />
            <Column title="Participants"
                render={(_, record) => (
                    <Space size="middle" className="hover:underline hover:cursor-pointer"
                           data-id={record.id}
                           data-dashboard={-1}
                           onClick={openParticipantPanel}
                    >
                        {record.participants.length}
                    </Space>
                )}
            />

            <Column title="D1"
                render={(_, record) => (
                    <Space size="middle" className="hover:underline hover:cursor-pointer"
                           data-id={record.id}
                           data-dashboard={0}
                           onClick={openParticipantPanel}
                    >
                        {record.d1}
                    </Space>
                )}
            />
            <Column title="D2"
                render={(_, record) => (
                    <Space size="middle" className="hover:underline hover:cursor-pointer"
                           data-id={record.id}
                           data-dashboard={1}
                           onClick={openParticipantPanel}
                    >
                        {record.d2}
                    </Space>
                )}
            />
            <Column title="D3"
                render={(_, record) => (
                    <Space size="middle" className="hover:underline hover:cursor-pointer"
                           data-id={record.id}
                           data-dashboard={2}
                           onClick={openParticipantPanel}
                    >
                        {record.d3}
                    </Space>
                )}
            />
            <Column title="D4"
                render={(_, record) => (
                    <Space size="middle" className="hover:underline hover:cursor-pointer"
                           data-id={record.id}
                           data-dashboard={3}
                           onClick={openParticipantPanel}
                    >
                        {record.d4}
                    </Space>
                )}
            />
            <Column title="D5"
                render={(_, record) => (
                    <Space size="middle" className="hover:underline hover:cursor-pointer"
                           data-id={record.id}
                           data-dashboard={4}
                           onClick={openParticipantPanel}
                    >
                        {record.d5}
                    </Space>
                )}
            />
            <Column title="D6"
                render={(_, record) => (
                    <Space size="middle" className="hover:underline hover:cursor-pointer"
                           data-id={record.id}
                           data-dashboard={5}
                           onClick={openParticipantPanel}
                    >
                        {record.d6}
                    </Space>
                )}
            />
            <Column
                title="Action"
                key="action"
                render={(_, record) => (
                <Space size="middle">
                    <Space.Compact direction="vertical">
                        <Space>
                            {
                            record.videos.length > 0 &&
                            <a data-id={record.videos[0].id}
                               href={record.videos[0].url}
                               target="_blank" rel="noopener noreferrer"
                            >
                                Video 1
                            </a>
                            }
                            {
                            record.videos.length > 1 &&
                            <a data-id={record.videos[1].id}
                               href={record.videos[1].url}
                               target="_blank" rel="noopener noreferrer"
                            >
                                Video 2
                            </a>
                            }
                        </Space>
                        <Space>
                            <a data-id={record.id} data-dashboard="0" onClick={openDashboard}>D1</a>
                            <a data-id={record.id} data-dashboard="1" onClick={openDashboard}>D2</a>
                            <a data-id={record.id} data-dashboard="2" onClick={openDashboard}>D3</a>
                            <a data-id={record.id} data-dashboard="3" onClick={openDashboard}>D4</a>
                            <a data-id={record.id} data-dashboard="4" onClick={openDashboard}>D5</a>
                            <a data-id={record.id} data-dashboard="5" onClick={openDashboard}>D6</a>
                        </Space>
                    </Space.Compact>
                    <a data-id={record.id} onClick={editExperiment}>Edit</a>
                    <Popconfirm
                        title="Delete the experiment"
                        description="Are you sure to delete this experiment?"
                        onConfirm={() => { handleDelete(record.id) }}
                        okButtonProps={{ className: "bg-activeBlue"}}
                        okText="Yes"
                        cancelText="No"
                    >
                        <a>Delete</a>
                    </Popconfirm>
                    <a data-id={record.id} onClick={downloadData}>Download Event Data</a>
                    <span className="hover:text-activeBlue cursor-pointer"
                          onClick={() => {
                            Modal.confirm({
                                icon: <ExclamationCircleOutlined/>,
                                okButtonProps: { className: "bg-activeBlue"},
                                content:
                                    <div className="w-full flex flex-col px-2 align-center items-center">
                                        <p className="text-md w-full">Are you sure to remove all unused research IDs from this experiment?</p>
                                        <div className="w-full h-64 overflow-y-scroll">
                                        <List
                                          dataSource={record.participants.filter(i => !i.active)}
                                          renderItem={(pItem) => (
                                                    <List.Item>
                                                        {pItem.id}
                                                    </List.Item>
                                         )}
                                        />
                                        </div>
                                    </div>,
                                onOk() {
                                    removeUnusedUsers(record.id)
                                },
                                onCancel() {
                                },
                            })
                          }}
                    >
                        Unlink Unused ID(s)
                    </span>
                </Space>
                )}
            />
        </Table>
        <TagPanel
            show={tagModal}
            videoId={videoId}
            tags={selectedTags}
            refreshData={getExperiments}
            onClose={closeModal}
        />
        <ManageSharePanel
            show={shownSharedPanel}
            users={sharedUsers}
            onClose={closeSharedPanel}
        />
        <Modal
            title="Edit Experiment"
            centered
            open={editModalOpen}
            onOk={() => submitEditedData()}
            onCancel={() => setEditModalOpen(false)}
            okButtonProps={{ className: "bg-activeBlue"}}
            width="60%"
        >
            <div className="w-full flex flex-row px-2 align-center items-center">
                <p className="mr-1 sm:w-1/4 lg:w-1/6">Experiment Title</p>
                <Input
                    placeholder="Enter video title"
                    className="flex-1 sm:w-1/2 text-base py-2 "
                    value={title}
                    onChange={(e) => setTitle(e.currentTarget.value)}
                />
            </div>
            <div className="mt-4 w-full flex px-2 align-center items-center">
                <p className="mr-1 sm:w-1/4 lg:w-1/6">Room ID</p>
                <Input
                    placeholder="Enter room ID"
                    className="flex-1 sm:w-1/2 text-base py-2 "
                    value={room}
                    onChange={(e) => setRoom(e.currentTarget.value)}
                />
            </div>
        </Modal>

        <Modal
            title="Participants"
            centered
            open={participantModalOpen}
            onOk={() => {
                setParticipantModalOpen(false)
                setSelectedParticipants([])
            }}
            onCancel={() => {
                setParticipantModalOpen(false)
                setSelectedParticipants([])
            }}
            okButtonProps={{ className: "bg-activeBlue"}}
            width="40%"
            size="small"
        >
            <div className="h-64 overflow-y-scroll">
            <List
              dataSource={selectedParticipants}
              renderItem={(item) => (
                <List.Item>
                    {item.id}
                </List.Item>
              )}
            />
            </div>
        </Modal>
    </div>
    )
}

export default LadderExperimentManagement