import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Image, Input } from "antd";
import uploadModalLogo from "../../assets/uploadModalLogo.svg";
import { message, Upload } from 'antd';

import { BASE_URL } from "../../services/server"
import axios from "axios";

import "../../assets/styles/tag.css";
import {useDisableBodyScroll} from "../../hooks/BodyScroll";


const { Dragger } = Upload;

const UploadExperimentModal = (props) => {
    useDisableBodyScroll(props.open);

    const [fileList, setFileList] = useState([])
    const [title, setTitle] = useState("")
    const [room, setRoom] = useState("")
    const [uploading, setUploading] = useState(false)

    const dispatch = useDispatch();

    const resetFields = () => {
        setUploading(false)
        setTitle("")
        setRoom("")
        setFileList([])
    }

    const handleUpload = () => {
        const formData = new FormData();

        formData.set("title", title);
        formData.set("room", room);
        formData.set("data", fileList[0]);

        setUploading(true)

        axios.post(BASE_URL + "xp-admin/upload", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            if (res.status === 201) {
                resetFields()
                message.success('Experiment has been uploaded')
                //props.onOk(res.data.url)
                props.onOk()
            }
        })
        .catch(() => {
            message.error('Experiment cannot be uploaded.');
        })
        .finally(() => {
            setUploading(false)
        });
    }

    const uploadProps = {
        name: 'file',
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xlsx',
        multiple: false,
        maxCount: 1,

        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        beforeUpload(file) {
            //setFileList([...fileList, file])
            setFileList([file])

            return false;
        },
        onRemove: (file) => {
            setFileList([])

            //   const index = state.fileList.indexOf(file);
            //   const newFileList = state.fileList.slice();
            //   newFileList.splice(index, 1);
            //   setFileList(newFileList)
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const handleCancel = () => {
        resetFields()
        props.onCancel()
    }

    return(
        <Modal
            title="Create an experiment"
            centered
            destroyOnClose={true}
            closable={false}
            open={props.open}
            onOk={props.onOk}
            onCancel={handleCancel}
            width={900}
            styles={{
                body: {
                    height: "500px"
                }
            }}
            footer={[
                <>
                    <div className="flex flex-row justify-center items-center">
                        <Button key="back" className="mr-8 px-12" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button
                            key="submit" type="primary" className="px-12 bg-primaryBlue"
                            onClick={handleUpload}
                            disabled={((fileList.length === 0) || (title.length === 0))}
                            loading={uploading}
                        >
                            Create
                        </Button>
                    </div>
                </>
            ]}
        >
            <>
                <div className="h-[100%] flex flex-col items-center">
                    <Dragger {...uploadProps} className="mt-4 mb-8">
                        <div className="px-64 py-10">
                            <Image
                                src={uploadModalLogo}
                                width={60}
                                preview={false}
                                className="mb-4"
                            />
                            <p className="ant-upload-text text-black">Upload a File</p>
                            <p className="ant-upload-hint">
                                Click to <span className="text-primaryBlue">browse</span>, or drag & drop a file here
                            </p>
                        </div>
                    </Dragger>
                    <div className="w-full flex flex-row px-8 align-center items-center">
                        <p className="text-lg mr-2">Experiment Title</p>
                        <Input
                            placeholder="Enter experiment title"
                            className="flex-1 sm:w-1/2 text-base py-2 "
                            value={title}
                            onChange={(e) => setTitle(e.currentTarget.value)}
                        />
                    </div>
                    <div className="w-full flex flex-row px-8 align-center items-center mt-4">
                        <p className="text-lg mr-2">Room ID</p>
                        <Input
                            placeholder="Enter Study UX ID"
                            className="flex-1 sm:w-1/2 text-base py-2 "
                            value={room}
                            onChange={(e) => setRoom(e.currentTarget.value)}
                        />
                    </div>
                </div>
            </>
        </Modal>
    )
}

export default UploadExperimentModal;