import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { useEffect, useState } from 'react';

import { Line } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";

const yMap = ['Stop', 'Start']

const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
        },
        zoom: {
            zoom: {
                wheel: {
                    enabled: true,
                },
                pinch: {
                    enabled: false
                },
                mode: 'x',
            },
            pan: {
                enabled: true,
                mode: 'x',
            }
        },
    },
    scales: {
        y: {
            min: 0,
            ticks: {
                stepSize: 1,
                min: 0,
                max: yMap.length - 1,
                callback: function(value) {
                    return yMap[value];
                },
            },
            grid: {
                color: "rgba(0, 0, 0, 0)",
            }
        },
        x: {
            min: 0,
            ticks: {
                stepSize: 1
            },
            grid: {
                color: "rgba(0, 0, 0, 0)",
            }
        }
    }
};


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
);

const ActiveTimeline = (props) => {
    const [loading, setLoading] = useState(true)
    const [noData, setNoData] = useState(true)
    const [videoDuration, setVideoDuration] = useState(0)
    const [chartData, setChartData] = useState([])
    
    const handleLoadedMetadata = (e) => {
        setVideoDuration(parseInt(Math.round(e.currentTarget.duration)))
    }
    
    useEffect(() => {
        const { data } = props

        if ((data === null) || data.timeline === null) {
            setNoData(true)
            setLoading(false)
        }
        else if (videoDuration !== null) {
            const dataLabels = [...Array(videoDuration).keys()]
            const { data } = props

            setNoData(false)

            let timelineState = {}
            Object.entries(data.timeline.activity).forEach(([_,state]) => {
                timelineState[state.ts] = state.value
            })

            let activeData = Array(videoDuration).fill(0)
            let lastState = 0
            for (let i=0; i < videoDuration; i++) {
                if (timelineState[i] !== undefined) {
                    lastState = timelineState[i]

                }

                activeData[i] = lastState
            }

            setChartData({
                labels: dataLabels,
                yLabels: ['Inactive', 'Active'],
                datasets: [
                    {
                        label: "seconds",
                        data: activeData,
                        stepped: true,
                        // backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        backgroundColor: 'rgba(75,192,192,0.4)',
                        borderColor: 'rgba(75,192,192,0.8)',
                        borderWidth: 1,
                        hoverBackgroundColor: 'rgba(75,192,192,0.8)',
                        hoverBorderColor: 'rgba(75,192,192,1)',
                    }
                ],
            })

            setNoData(false)
            setLoading(false)
        }
    }, [videoDuration, props])

    if (loading) return <div>Loading</div>

    return(
        <div className="h-full flex-1 flex flex-col items-center justify-center overflow-hidden">
            <h2 className="mb-2 font-bold">
                Timer Display
            </h2>
            <div className="w-full px-4 flex-1">
                <video className="hidden"
                    src={props.data !== null ? props.data.url : ""}
                    onLoadedMetadata={handleLoadedMetadata}
                />
                {
                noData ?
                    <span className="flex text-gray-600 mt-10">No data to display</span>
                :
                    <Line
                        data={chartData}
                        options={options}
                    />
                }
            </div>
        </div>
    )
}

export default ActiveTimeline;