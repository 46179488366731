import {useSelector} from "react-redux";

import { Image } from 'antd';
import LoginPanel from "../components/LoginPanel";
import loginImage from '../assets/loginImage.png'
import {useNavigate} from "react-router-dom";


export default function Login(){
    const { isLoggedIn } = useSelector((state) => state.auth);
    const navigate = useNavigate()

    if (isLoggedIn) navigate('/')

    return(
        <div className="flex-row flex min-h-screen">
            <LoginPanel />
            <div className="hidden md:flex flex-1 bg-primaryBackground justify-center items-center">
                <Image
                    width={600}
                    src="/img/loginImage.png"
                    preview={false}
                />
            </div>
        </div>
    )
} 