import {useEffect, useState} from "react";
import {Button, Input, message, Space, Switch, Table} from 'antd'
import axios from "axios";
import {BASE_URL} from "../../services/server";
import {useNavigate} from "react-router-dom";
import TagPanel from "../Modal/TagPanel";
import ManageSharePanel from "../Modal/ManageSharePanel";

const { Column, ColumnGroup } = Table;

const VideoManagement = (props) => {
    const [tagModal, setTagModal] = useState(false)
    const [videos, setVideos] = useState([])
    const [shownSharedPanel, setShownSharedPanel] = useState(false)
    const [sharedUsers, setSharedUsers] = useState([])
    const [selectedTags, setSelectedTags] = useState([])
    const [videoId, setVideoId] = useState(null)
    const navigate = useNavigate();

    useEffect(() => {
        getVideos()
    }, [])

    const getVideos = () => {
        axios.get(BASE_URL + 'video/manage')
            .then((res) => {
                const videoItems = res.data.items.map(obj =>
                    ({
                        ...obj,
                        owner: obj.user.name
                    })
                );
                setVideos(videoItems)
            })
    }

    const switchVideoTimer = (checked, e) => {
        const targetId = parseInt(e.currentTarget.dataset.id)

        axios.post(
            BASE_URL + "video/manage",
            {
                id: targetId,
                timer: checked
            }
        )
        .then((resp) => {
            message.success('Video timer has been updated.')
        })
        .catch((err) => {
            message.error('Video timer could not be saved')
        })
    }

    const manageVideoTag = (e) => {
        const targetId = parseInt(e.currentTarget.dataset.id)
        setVideoId(e.currentTarget.dataset.id)

        const selectedVideo = videos.filter(v => v.id === targetId)
        if (selectedVideo.length > 0) {
            setSelectedTags(selectedVideo[0].tags)
        }
        else setSelectedTags([])

        setTagModal(true)
    }
    const closeModal = () => {
        setTagModal(false)
        setVideoId(null)
    }
    const openVideo = (e) => {
        const videoUrl = '/video/' + e.currentTarget.dataset.id
        navigate(videoUrl, { state: { new_video: false } });
    }

    const openSharedPanel = (e) => {
        const targetId = e.currentTarget.dataset.id
        const selectedVideo = videos.filter(e => (e.id === parseInt(targetId)))

        if (selectedVideo.length > 0) {
            setSharedUsers(selectedVideo[0].shared_with.sort((a, b) => (a.name > b.name ? 1 : -1)))
        }
        else setSharedUsers([])
        setShownSharedPanel(true)
    }
    const closeSharedPanel = () => {
        setShownSharedPanel(false)
        setSharedUsers([])
    }

    return (
    <div className="">
        <Table dataSource={videos} rowKey="uuid">
            <Column title="Video" width="40%" dataIndex="title" key="title" />
            <Column title="Owner" dataIndex="owner" key="owner" />
            <Column title="Shared with"
                render={(_, record) => (
                    <Space size="middle" className="hover:underline hover:cursor-pointer"
                           data-id={record.id}
                           onClick={openSharedPanel}
                    >
                        {
                            record.shared_with.length + " " +
                            (record.shared_with.length > 0 ? "users" : "user")
                        }
                    </Space>
                )}
            />
            <Column
                title="Action"
                key="action"
                render={(_, record) => (
                    <Space size="middle">
                        <a data-id={record.id} onClick={openVideo}>View video</a>
                        <a data-id={record.id} onClick={manageVideoTag}>Manage tag</a>
                        <Switch
                            defaultChecked={record.timer}
                            data-id={record.id}
                            className="bg-gray-400"
                            checkedChildren="Timer on" unCheckedChildren="Timer off"
                            onChange={switchVideoTimer}
                        />
                    </Space>
                )}
            />
        </Table>
        <TagPanel
            show={tagModal}
            videoId={videoId}
            tags={selectedTags}
            refreshData={getVideos}
            onClose={closeModal}
        />
        <ManageSharePanel
            show={shownSharedPanel}
            users={sharedUsers}
            onClose={closeSharedPanel}
        />
    </div>
    )
}

export default VideoManagement