import React, { useEffect, useState } from "react";
import uploadLogo from "../assets/uploadLogo.svg";
import Input from "antd/es/input/Input";
import { Select, Button, Image, Tabs } from "antd";

import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";

import * as video from "../store/video";

import { BASE_URL } from "../services/server"
import { API_URL } from "../services/video.services";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ListMyVideo from "../components/videos/ListMyVideo";
import {useNavigate} from "react-router-dom";
import {useLocation} from "react-router";
import LadderVideoCard from "../components/videos/LadderVideoCard";

const { Search } = Input;

// const videosData = videos;
const DEFAULT_SORT_OPT = 'latest'

export default function Video() {
    let cancelToken = null
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ shownVideos, setShownVideos] = useState({ mine: [], others: [] })
    const { videos, perPage, totalItems } = useSelector((state) => state.video);
    const { user } = useSelector((state) => state.auth);

    const [experiments, setExperiments] = useState([])
    const [keywords, setKeywords] = useState("")
    const [sortOpt, setSortOpt] = useState(DEFAULT_SORT_OPT);

    const navigate = useNavigate();
    const { state } = useLocation();

    const getExperiments = () => {
        axios.get(BASE_URL + 'ladder')
            .then((res) => {
                const oneXp = res.data.items.slice(0,1)
                setExperiments(oneXp)
        })
    }

    useEffect(() => {
        getExperiments()

    }, [])


    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleModalOk = (videoUrl) => {
        listVideo(keywords, sortOpt)
        setIsModalOpen(false);

        navigate(videoUrl, { state: { new_video: true } });
    };
    
    const handleModalCancel = () => {
        setIsModalOpen(false);
    };

    const checkNewContent = () => {
        axios.get(
            API_URL + "/notifications",
        )
            .then((resp) => {
                // setNotificationData(resp.data)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {

            })
    }

    const searchVideo = async (keywords, sortOpt) => {
        //Check if there are any previous pending requests
        if ((cancelToken !== null) && (typeof cancelToken != typeof undefined)) {
            cancelToken.cancel("Operation canceled due to new request.")
        }
        //Save the cancel token for the current request
        cancelToken = axios.CancelToken.source()

        const searchParams = (keywords.length > 0) ? "&search_query=" + keywords : ""

        try {
            const response = await axios.get(
                API_URL + "?sort=" + sortOpt + searchParams,
                { cancelToken: cancelToken.token } // Pass the cancel token to the current request
            )

            // console.log("Results for " + searchParams + ": ", )
            return response.data
        } catch (error) {
            console.log(error)
        } finally {
            cancelToken = null
        }
        return null
    }

    const listVideo = (keywords="", sort="latest") => {
        // searchVideo(keywords).then((results) => {
        //     if (results !== null) dispatch(video.setVideo(results))
        // })


    }

    const onSearchChange = (e) => {
        const value = e.currentTarget.value.toLowerCase()
        setKeywords(value)
    }

    const onSearch = (value) => {
        listVideo(keywords, sortOpt)
    }

    const onSortChange = (value) => {
        setSortOpt(value)
    }

    const refreshData = () => {
        getExperiments()
    }


    useEffect(() => {
        if (user !== null) {
            let mine = videos.slice(0)
            let others = videos.slice(0)
            
            mine = mine.filter((el) => el.user.uuid === user.uuid)
            others = others.filter((el) => el.user.uuid !== user.uuid)
    
            setShownVideos({ mine, others })
        }
    }, [videos, user])

    const onChange = (key) => {
        // console.log(key);
    }
    
    return(
        <div className="Video flex flex-row min-h-screen">
            <div className="w-full bg-primaryBackground flex flex-col justify-center items-center">
                <Topbar />
                <div className="w-full mt-4 container px-4 flex flex-col min-h-screen items-center">
                    {experiments.map(xp_data => {
                        return <LadderVideoCard
                            key={xp_data.uuid}
                            data={xp_data}
                            refreshData={refreshData}
                        />
                    })}
                </div>
            </div>
        </div>
    )
} 