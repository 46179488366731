import React, {useEffect, useState} from "react";
import {Button, Input, message, Modal, Popconfirm, Space, Table} from 'antd'

import axios from "axios";
import {BASE_URL} from "../../services/server";

const { Column, ColumnGroup } = Table;

const TagManagement = (props) => {
    const [data, setData] = useState({
        total_items: 0,
        items_per_page: 10,
        page: 1,
        total_pages: 1,
        items: []
    })
    const [newTag, setNewTag] = useState("")
    const [updatedTagId, setUpdatedTagId] = useState(-1)
    const [updatedTag, setUpdatedTag] = useState("")
    const [editTagModalOpen, setEditTagModalOpen] = useState(false)

    useEffect(() => {
        getTags(1, 25)
    }, [])

    const getTags = (page, size) => {
        axios.get(BASE_URL + `tag/master?page=${page}&per_page=${size}`)
            .then((res) => {
                setData(res.data)
            })
    }

    const changeNewTag = (e) => {
        setNewTag(e.currentTarget.value)
    }

    const addTag = () => {
        if (newTag.length > 0) {
            axios.post(BASE_URL + 'tag/master', {
                tag: newTag,
                text: newTag,
            }).then((res) => {
                getTags(1, 25)
                message.success('Tag has been added')
                setNewTag("")
            }).catch((err) => {
                message.error(err.response.data.message)
            })
        }
    }

    const editTag = () => {
        if (updatedTag.length > 0) {
            axios.patch(BASE_URL + 'tag/master/' + updatedTagId, {
                tag: updatedTag,
            }).then((res) => {
                getTags(1, 25)
                message.success('Tag has been updated')
                closeEditModal()
            }).catch((err) => {
                message.error(err.response.data.message)
            })
        }
        else message.error('Please enter a valid tag')
    }

    const deleteTag = (targetId) => {
        // const targetId = e.currentTarget.dataset.id

        axios.delete(BASE_URL + 'tag/master/' + targetId)
            .then((res) => {
                getTags(1, 25)
                message.success('Tag has been removed')
            }).catch((err) => {
                message.error(err.response.data.message)
            })
    }

    const openEditModal = (e) => {
        const tagData = e.currentTarget.dataset

        setUpdatedTagId(tagData.id)
        setUpdatedTag(tagData.tag)
        setEditTagModalOpen(true)
    }

    const closeEditModal = () => {
        setUpdatedTagId(-1)
        setUpdatedTag('')
        setEditTagModalOpen(false)
    }

    return (
    <div className="">
        <div className="flex border py-4 px-2 mb-4">
            <span className="flex align-center items-center mb-2 text-md mr-2">
                Add New Tag
            </span>
            <Input
                className="w-1/2 mr-2"
                value={newTag}
                onChange={changeNewTag}
                onPressEnter={addTag}
            />
            <Button onClick={addTag}>
                Add
            </Button>
        </div>
        <Table dataSource={data.items} rowKey="id"
               pagination={{
                   position: ["topRight", "bottomRight"],
                   current: data.page,
                   total: data.total_items,
                   pageSize: data.items_per_page,
                   showSizeChanger: true,
                   pageSizeOptions: [5, 10, 25, 50, 100],
                   showTotal: (total, range) => `Total tags: ${total}`,
                   onChange: (page, size) => {
                       getTags(page, size)
                   }
               }}
        >
            <Column title="Tag" dataIndex="text" key="text" />
            <Column
                title="Action"
                key="action"
                render={(_, record) => (
                    <Space size="middle">
                        <span
                            className="hover:text-activeBlue cursor-pointer"
                            data-id={record.id}
                            data-tag={record.tag}
                            onClick={openEditModal}
                        >
                            Edit
                        </span>
                        <Popconfirm
                            title="Delete tag"
                            description="Are you sure to delete this tag?"
                            onConfirm={() => { deleteTag(record.id) }}
                            okButtonProps={{ className: "bg-activeBlue"}}
                            okText="Yes"
                            cancelText="No"
                        >
                        <span className="hover:text-activeBlue cursor-pointer">Delete</span>
                        </Popconfirm>
                    </Space>
                )}
            />
        </Table>
        <Modal
            title="Edit Tag"
            centered
            open={editTagModalOpen}
            onOk={editTag}
            onCancel={closeEditModal}
            okButtonProps={{ className: "bg-activeBlue"}}
            width="60%"
        >
            <div className="w-full flex flex-row px-2 align-center items-center">
                <p className="mr-1 sm:w-1/4 lg:w-1/6">Tag</p>
                <Input
                    placeholder="Enter tag"
                    className="flex-1 sm:w-1/2 text-base py-2 "
                    value={updatedTag}
                    onChange={(e) => setUpdatedTag(e.currentTarget.value)}
                />
            </div>
        </Modal>
    </div>

    )
}

export default TagManagement