import React, { useState } from "react";

import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";


export default function Layout(props) {
    return (
        <div className="flex flex-row min-h-screen">
            <div
                className="w-full bg-primaryBackground flex flex-col justify-center items-center"
            >
                <Topbar />
                <div className={"w-full container flex flex-col min-h-screen " + props.className}>
                    {
                        props.children
                    }
                </div>
            </div>
        </div>
    )
}
