import axios from "axios";
import React, { useEffect, useState, useRef } from "react"
import {message, Select, Space, Spin} from "antd"
import { WithContext as ReactTags } from 'react-tag-input';

import * as video from "../../store/video";

import "../../assets/styles/tag.css";
import { BASE_URL } from "../../services/server";
import { useDispatch } from "react-redux";
import { useDisableBodyScroll } from "../../hooks/BodyScroll";
import {DeleteOutlined, ReloadOutlined} from "@ant-design/icons";

const KeyCodes = {
    comma: 188,
    enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
const suggestions = [
    {id: "0", text: "Suggested Tag 1"},
    {id: "1", text: "Suggested Tag 2"},
    {id: "2", text: "Value Tag"},
    {id: "2", text: "Good movement"}
]

const TagPanel = (props) => {
    useDisableBodyScroll(props.show);

    const [tagLoading, setTagLoading] = useState(false)
    const [newTags, setNewTags] = useState([]);
    const [optTags, setOptTags] = useState([])
    // const [tags, setTags] = useState(props.tags.map(t => t.id));
    const [tags, setTags] = useState([]);

    const prevVideoRef = useRef();

    useEffect(() => {
        getTags()
    }, [])

    useEffect(() => {
        if (
            (prevVideoRef.current !== undefined) &&
            (prevVideoRef.current !== null) &&
            (prevVideoRef.current.show !== props.show)
        ) {
            setTags(props.tags.map(t => t.id))
        }

        prevVideoRef.current = props
    }, [props])

    const getTags = () => {
        setTagLoading(true)

        axios.get(BASE_URL + 'tag/master')
            .then((res) => {
                let opt = res.data.items.map((t, idx) => (
                    {
                        id: t.id,
                        value: t.id,
                        label: t.text,
                        text: t.text,
                        tag: t.tag,
                    }
                ))

                setOptTags(opt)
            }).finally(() => {
                setTagLoading(false)
            })
    }

    const handleSubmit = () => {
        const existingTags = props.tags.filter(t => tags.includes(t.id))
        const addedTags = newTags.filter(t => !tags.includes(t.id))

        axios.post(
            BASE_URL + "video/manage",
            {
                id: props.videoId,
                tags: [
                    ...existingTags,
                    ...addedTags,
                ],
            }
        )
        .then((resp) => {
            props.refreshData && props.refreshData()
            closeModal()
            message.success('Video Tags has been updated.')
        })
        .catch((err) => {
            message.error('Video Tags could not be saved')
        })
    }

    const resetData = () => {
        setNewTags([])
        setTags([])
    }

    const closeModal = () => {
        resetData()
        props.onClose && props.onClose();
    }

    if (!props.show) return null;

    const onSelectTag = (value) => {
        let selectedTags = optTags.filter(t => t.value === value)
        if (selectedTags.length > 0) {
            let newT = newTags.filter(t => t.value === selectedTags[0].value)
            if (newT.length === 0) {
                newT = [...newTags, selectedTags[0]]
                newT.sort((a, b) => (a.label < b.label ? -1 : 1))

                setNewTags(newT)
            }
        }
    }

    const handleDeleteNewTag = (tagId) => {
        setNewTags(
            newTags.filter(t => t.value !== tagId)
        )
    }
    const deleteTag = (tagId) => {
        setTags(tags.filter(t => t !== tagId))
    }

    const undoDeleteTag = (tagId) => {
        setTags([...tags, tagId])
    }


    return (
            <div className="py-16 transition duration-150 ease-in-out z-10 fixed top-0 right-0 bottom-0 left-0" id="modal"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}>
                <div role="alert" className="container mx-auto w-11/12 md:w-2/3 max-w-2xl">
                    <div className="relative py-8 px-2 md:px-10 bg-white shadow-md rounded border border-gray-400">
                        <h1 className="text-gray-800 font-lg font-bold tracking-normal leading-tight mb-4">
                            Manage Video Default Tag
                        </h1>
                        <div className="flex gap-2">
                            <Select
                                showSearch
                                className="w-full mb-2 flex-1"
                                placeholder="Select a tag"
                                optionFilterProp="children"
                                value={null}
                                onChange={onSelectTag}
                                filterOption={(input, option) =>
                                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            >
                            {
                                optTags.map((opt, idx) => (
                                    <Select.Option key={idx} value={opt.id} label={opt.label}>
                                        <Space className="flex flex-col items-start">
                                            <div>{opt.text}</div>
                                        </Space>
                                    </Select.Option>
                                ))
                            }
                            </Select>

                            {
                                tagLoading ?
                                    <div className="flex items-center justify-center">
                                        <Spin />
                                    </div>
                                    :
                                    <button className="flex border border-blue-400 rounded-md h-8 w-8 items-center justify-center"
                                    onClick={() => getTags()}
                                    >
                                        <ReloadOutlined/>
                                    </button>
                            }

                        </div>

                        <label htmlFor="tags" className="text-gray-800 text-sm font-bold leading-tight tracking-normal">
                            To be added ({ newTags.length })
                        </label>
                        <div className="mb-4 flex flex-col h-24 overflow-scroll border text-sm">
                            {
                                newTags.map((newT, idx) => (
                                    <div key={idx}
                                         className={"py-1 px-2 flex justify-between hover:bg-gray-200 " + ((idx % 2 === 0) ? "" : "bg-gray-100")}>
                                        <div className="flex flex-col">
                                            <span>{ newT.text }</span>
                                        </div>

                                        <div className="flex justify-end gap-2">
                                            <span className="flex items-center cursor-pointer"
                                                 onClick={() => handleDeleteNewTag(newT.value)}
                                            ><DeleteOutlined /></span>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                        <label htmlFor="tags" className="text-gray-800 text-sm font-bold leading-tight tracking-normal">
                            Default Tag(s)
                        </label>
                        <div className="flex flex-col h-56 overflow-scroll border text-sm">
                            {
                                props.tags.map((t, idx) => (
                                    <div key={idx}
                                         className={"py-1 px-2 flex justify-between hover:bg-gray-200 " + ((idx % 2 === 0) ? "" : "bg-gray-100")}>
                                        <div className="flex flex-col">
                                            <span>{ t.text }</span>
                                        </div>
                                        {
                                            tags.includes(t.id) ?
                                                <div className="flex justify-end gap-2">
                                                    <span className="flex items-center cursor-pointer"
                                                          onClick={() => deleteTag(t.id)}
                                                    >
                                                        <DeleteOutlined />
                                                    </span>
                                                </div>
                                            :
                                                <span className="flex flex-col text-right">
                                                    <span className="text-red-600">To be deleted</span>
                                                    <span
                                                        className="hover:underline cursor-pointer text-xs"
                                                        onClick={() => { undoDeleteTag(t.id) }}
                                                    >Cancel</span>
                                                </span>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                        <div className="mt-8 flex items-center justify-between w-full">
                            <button
                                className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-sm"
                                onClick={closeModal}
                            >
                                Cancel
                            </button>
                            <button
                                className="ml-4 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-activeBlue transition duration-150 ease-in-out hover:bg-activeBlue bg-primaryBlue rounded text-white px-8 py-2 text-sm"
                                onClick={handleSubmit}
                            >
                                Save
                            </button>
                        </div>
                        <button
                            className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
                            onClick={closeModal} aria-label="close modal"
                        >
                            <svg  xmlns="http://www.w3.org/2000/svg"  className="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
    )
}

export default TagPanel;