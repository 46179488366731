import {useSelector} from "react-redux";

import {Link, useNavigate} from "react-router-dom";
import React from "react";


export default function NotAllowed(){
    const { isLoggedIn } = useSelector((state) => state.auth);
    const navigate = useNavigate()

    if (isLoggedIn) navigate('/')

    return(
        <div className="flex-row flex min-h-screen">
            <div className="w-full text-center">
                <h1 className="mt-16 md:mt-32 p-8 text-6xl">
                    Access denied
                </h1>
                <p className="text-lg mb-12">
                    Sorry, you are not allowed to access this application.
                </p>
            </div>
        </div>
    )
}