
import React, { useEffect, useState } from "react";
import Input from "antd/es/input/Input";
import {Select, Button, Image, Tabs, message} from "antd";

import { BASE_URL } from "../services/server"
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { validateEmail } from "../helpers/validator";
import {useNavigate} from "react-router-dom";
import * as auth from "../store/auth";

export default function SetupProfileLadder() {
    const { user } = useSelector((state) => state.auth);
    const [rId, setRId] = useState("")
    const [schools, setSchools] = useState([])
    const [email, setEmail] = useState("")
    const [emailStatus, setEmailStatus] = useState("")
    const [selectedSchool, setSelectedSchool] = useState(null)
    const [schoolStatus, setSchoolStatus] = useState("")

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        // searchSchool()
    }, [])

    const searchSchool = () => {
        axios.get(
            BASE_URL + "school",
        )
        .then((resp) => {
            let optSchools = resp.data.map((sch) => ({
                value: sch.uuid,
                label: sch.name
            }))
            const actOptions = optSchools.filter(sch => sch.label !== 'Others')
            const altOptions = optSchools.filter(sch => sch.label === 'Others').map(opt => ({
                ...opt,
                label: opt.label + ' (Select this if you are not able to find your school or workplace in the list.)'
            }))

            optSchools = [
                ...altOptions,
                ...actOptions
            ]

            setSchools(optSchools)
        })
        .catch((err) => {
            message.success("Could not fetch list of schools.")
        })
    }

    const setupProfile = () => {
        axios.post(
            BASE_URL + "ladder/setup",
            {
                id: rId
            }
        )
        .then((resp) => {
            dispatch(auth.checkLogin()).then(res => {
                navigate('/video')
            })

        })
        .catch((err) => {
            if (err.response.status === 409) {
                message.error("ID has been used in another account.");
            }
            else {
                message.error(err.message)
            }
        })
    }

     const onChange = (value) => {
        setSelectedSchool(value)
    };

    const validateSchool = (schoolId) => {
        try {
            const results = schools.filter(sch => sch.value === schoolId)
            if (results.length > 0) return true
        }
        catch (err) {}

        return false
    }

    const onSubmit = () => {
        //const isEmailValid = validateEmail(email)
        // const isSchoolValid = validateSchool(selectedSchool)

        // setEmailStatus(isEmailValid ? "" : "error")
        // setSchoolStatus(isSchoolValid ? "" : "error")

        if (rId.length > 0) {
            setupProfile()
        }
    }

    return(
        <div className="Video flex flex-row min-h-screen">
            <div className="w-full bg-primaryBackground flex flex-col p-4 items-center">
                <div className="text-center text-xl mb-10">
                    Please complete your profile
                </div>
                <div className="w-full md:w-1/2 items-center">
                    <div className="flex flex-col items-center mb-8">
                        <p className="mb-2">Name</p>
                        <Input
                            disabled={true}
                            value={user ? user.name : ""}
                        />
                    </div>

                    <div className="flex flex-col items-center mb-8">
                        <p className="mb-2">
                            Ladder ID
                        </p>
                        <Input
                            placeholder="Enter your Ladder ID"
                            value={rId}
                            onChange={(e) => { setRId(e.currentTarget.value.toUpperCase()) }}
                        />
                    </div>
                    <div className="flex justify-center mb-8">
                        <Button
                            type="primary" htmlType="submit"
                            className="bg-primaryBlue h-8"
                            onClick={onSubmit}>
                            <div className="flex flex-row justify-center items-center px-1">
                                <div className="text-uploadVideoButton">Submit</div>
                            </div>
                        </Button>
                    </div>
                </div>
            </div>         
        </div>
    )
} 