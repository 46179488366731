
import React, { useEffect, useState } from "react";
import Input from "antd/es/input/Input";
import {Select, Button, Image, Tabs, message} from "antd";

import { BASE_URL } from "../services/server"
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { validateEmail } from "../helpers/validator";
import {useNavigate} from "react-router-dom";
import * as auth from "../store/auth";


export default function SetupProfile() {
    const { user } = useSelector((state) => state.auth);
    const [schools, setSchools] = useState([])
    const [email, setEmail] = useState("")
    const [emailStatus, setEmailStatus] = useState("")
    const [selectedSchool, setSelectedSchool] = useState(null)
    const [schoolStatus, setSchoolStatus] = useState("")

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        searchSchool()
    }, [])

    const searchSchool = () => {
        axios.get(
            BASE_URL + "school",
        )
        .then((resp) => {
            let optSchools = resp.data.map((sch) => ({
                value: sch.uuid,
                label: sch.name
            }))
            const actOptions = optSchools.filter(sch => sch.label !== 'Others')
            const altOptions = optSchools.filter(sch => sch.label === 'Others').map(opt => ({
                ...opt,
                label: opt.label + ' (Select this if you are not able to find your school or workplace in the list.)'
            }))

            optSchools = [
                ...altOptions,
                ...actOptions
            ]

            setSchools(optSchools)
        })
        .catch((err) => {
            message.success("Could not fetch list of schools.")
        })
    }

    const setupProfile = () => {
        axios.post(
            BASE_URL + "user/setup",
            {
                email,
                school: selectedSchool
            }
        )
        .then((resp) => {
            dispatch(auth.checkLogin()).then(res => {
                navigate('/video')
            })

        })
        .catch((err) => {
            if (err.response.status === 409) {
                message.error("Email has been used in another account.");
            }
            else {
                message.error(err.message)
            }
        })
    }

     const onChange = (value) => {
        setSelectedSchool(value)
    };

    const validateSchool = (schoolId) => {
        try {
            const results = schools.filter(sch => sch.value === schoolId)
            if (results.length > 0) return true
        }
        catch (err) {}

        return false
    }

    const onSubmit = () => {
        const isEmailValid = validateEmail(email)
        const isSchoolValid = validateSchool(selectedSchool)

        setEmailStatus( isEmailValid ? "" : "error")
        setSchoolStatus(isSchoolValid ? "" : "error")

        if (isEmailValid && isSchoolValid) {
            setupProfile()
        }
    }

    return(
        <div className="Video flex flex-row min-h-screen">
            <div className="w-full bg-primaryBackground flex flex-col p-4 items-center">
                <div className="text-center text-xl mb-10">
                    Please complete your profile
                </div>
                <div className="w-full md:w-1/2 items-center">
                    <div className="flex flex-col items-center mb-8">
                        <p className="mb-2">Name</p>
                        <Input
                            disabled={true}
                            value={user ? user.name : ""}
                        />
                    </div>
                    <div className="flex flex-col items-center mb-8">
                        <p className="mb-2">
                            Email
                            <span className="ml-2 text-sm text-gray-600">(yourname@schools.gov.sg or yourname@moe.gov.sg or work email address)</span>
                        </p>
                        <Input
                            placeholder="Enter your email"
                            status={emailStatus}
                            value={email}
                            onChange={(e) => { setEmail(e.currentTarget.value.toLowerCase()) }}
                        />
                        {
                            emailStatus.length > 0 &&
                            <span className="text-sm text-red-600 mt-1">
                                Please enter a valid email address
                            </span>
                        }
                    </div>
                    <div className="flex flex-col items-center mb-8">
                        <p className="mb-2">School</p>
                        <Select
                            showSearch
                            className="w-full "
                            placeholder="Select a school"
                            optionFilterProp="children"
                            status={schoolStatus}
                            onChange={onChange}
                            value={selectedSchool}
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={schools}
                          />
                        {
                            schoolStatus.length > 0 &&
                            <span className="text-sm text-red-600 mt-1">
                                Please select a valid school
                            </span>
                        }
                    </div>
                    <div className="flex justify-center mb-8">
                        <Button
                            type="primary" htmlType="submit"
                            className="bg-primaryBlue h-8"
                            onClick={onSubmit}>
                            <div className="flex flex-row justify-center items-center px-1">
                                <div className="text-uploadVideoButton">Submit</div>
                            </div>
                        </Button>
                    </div>
                </div>
            </div>         
        </div>
    )
} 