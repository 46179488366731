const arrayToTree = (list) => {
    let map = {}, node, roots = []
    
    for (let i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
    }
    
    for (let i = 0; i < list.length; i += 1) {
        node = list[i];
        if ((node.parent_id !== null) && (node.parent_id !== 0)) {
            // if you have dangling branches check that map[node.parent_id] exists
            list[map[node.parent_id]].children.push(node)
        } else {
            roots.push(node);
        }
    }
    
    return roots.filter(el => ((el.parent_id === null) || (el.parent_id === 0)));
}

const TreeHelper = {
    arrayToTree,
}

export default TreeHelper;