import React, { useState, useEffect } from "react";
import "../App.css";

import {Link, useParams} from "react-router-dom";
import Layout from "./Layout";
import AnalyticsCard from "../components/analytics"
import { useDispatch, useSelector } from "react-redux";

import * as video from "../store/video";
import {LeftOutlined} from "@ant-design/icons";

const Analytics = (props) => {
    let { videoId } = useParams();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true)
    const { selectedVideo, loadingSingle } = useSelector((state) => state.video);
    
    useEffect(() => {
        dispatch(video.get(videoId))
    }, [dispatch, videoId])

    const videoTitle =  (selectedVideo !== null) ? selectedVideo.title : ""

    return(
        <Layout className="pl-4">
            <div className="flex flex-col w-full font-medium text-lg">
                <Link to={"/video/" + videoId} className="mt-2 inline-flex items-center" title={videoTitle}>
                    <LeftOutlined className="mr-1" /><span>{videoTitle}</span>
                </Link>
                <span className="mt-2 text-center">Dashboard</span>
            </div>
            <div className="container w-full mt-4 grid grid-cols-2 gap-4">
                <div className="border border-black rounded-sm overflow-hidden pt-2 h-64">
                    <AnalyticsCard.DiscussionNetwork
                        data={selectedVideo}
                    />
                </div>
                <div className="col-span-1 border border-black rounded-sm overflow-hidden pt-2 h-64">
                    <AnalyticsCard.ActiveTimeline
                        data={selectedVideo}
                    />
                </div>

                <div className="border border-black rounded-sm pt-2 h-64">
                    <AnalyticsCard.TagTally
                        data={selectedVideo}
                    />
                </div>

                <div className="border border-black rounded-sm pt-2 h-64">
                    <AnalyticsCard.MyTags
                        data={selectedVideo}
                    />
                </div>
            </div>
        </Layout>
    )
}

export default Analytics