import axios from "axios";

import { BASE_URL } from "./server"

const API_URL = BASE_URL + "auth/";

export const ACCESS_TOKEN_NAME = 'covaapd_access_token'
export const REFRESH_TOKEN_NAME = 'covaapd_refresh_token'


const register = (username, email, password) => {
    return axios.post(API_URL + "signup", {
        username,
        email,
        password,
    });
};

const login = (email, password) => {
    return axios
        .post(API_URL + "login", {
            email,
            password,
        })
        .then((response) => {
            if (response.data.access_token) {
                localStorage.setItem(ACCESS_TOKEN_NAME, response.data.access_token);
            }

            return response.data;
        });
};


let call = {};
const once = (config = {}, requestType = 'unnamed') => {
    if (call[requestType]) {
        call[requestType].cancel("Only one request allowed at a time. Cancelling first.");
    }
    call[requestType] = axios.CancelToken.source();
    config.cancelToken = call[requestType].token
    return axios(config);
}

const checkLogin = () => {
    return once({
        method: "get",
        url: API_URL + "user",
    })
    .then(response => {
        return response.data;
    })
    .catch(error => {
        throw(error)
    });
};


const getUser = (token) => {
    return axios
        .get(API_URL + "user", {
            headers: {
              'Authorization': "Bearer " + token
            }
        })
        .then((response) => {
            return response.data;
        });
};

const logout = () => {
    localStorage.removeItem(ACCESS_TOKEN_NAME);
};

const authService = {
    ACCESS_TOKEN_NAME,
    REFRESH_TOKEN_NAME,
    register,
    login,
    checkLogin,
    getUser,
    logout,
};

export default authService;