import axios from 'axios';

import { ACCESS_TOKEN_NAME } from './auth.services'

const setupServices = () => {
    axios.interceptors.request.use(
        config => {
            if (config.headers['Content-Type'] !== "multipart/form-data") {
                config.headers['Content-Type'] = 'application/json';
            }

            let token = localStorage.getItem(ACCESS_TOKEN_NAME)

            if (token) {
                config.headers.Authorization =  token ? `Bearer ${token}` : '';    
            }
            
            //config.headers['x-auth-token'] = token ? token : '';
            
            return config
        },
        error => {
            return Promise.reject(error)
        }
    )
    
    axios.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            if (error.response && 
                (error.response.status === 401 || error.response.data.message === '401 Unauthorized')
            ) {
                if (localStorage.getItem(ACCESS_TOKEN_NAME) !== null) {
                    localStorage.removeItem(ACCESS_TOKEN_NAME);
                    window.location = '/login';
                }
            }
            return Promise.reject(error)
        }
    )
}

export default setupServices;