import React, { useEffect, useState } from "react";
import uploadLogo from "../assets/uploadLogo.svg";
import Input from "antd/es/input/Input";
import { Select, Button, Image, Tabs } from "antd";

import UploadVideoModal from "../components/UploadVideoModal";

import * as video from "../store/video";

import { BASE_URL } from "../services/server"
import { API_URL } from "../services/video.services";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ListMyVideo from "../components/videos/ListMyVideo";
import {useNavigate} from "react-router-dom";
import {useLocation} from "react-router";
import Layout from "./Layout";

const { Search } = Input;

// const videosData = videos;
const DEFAULT_SORT_OPT = 'latest'

export default function Video() {
    let cancelToken = null
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ shownVideos, setShownVideos] = useState({ mine: [], others: [] })
    const { videos, perPage, totalItems } = useSelector((state) => state.video);
    const { user } = useSelector((state) => state.auth);
    // console.log(user)

    const [keywords, setKeywords] = useState("")
    const [sortOpt, setSortOpt] = useState(DEFAULT_SORT_OPT);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    
    useEffect(() => {
        // dispatch(video.list({keywords: ""}))
        listVideo(keywords, sortOpt)
    }, [])


    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleModalOk = (videoUrl) => {
        listVideo(keywords, sortOpt)
        setIsModalOpen(false);

        navigate(videoUrl, { state: { new_video: true } });
    };
    
    const handleModalCancel = () => {
        setIsModalOpen(false);
    };

    const checkNewContent = () => {
        axios.get(
            API_URL + "/notifications",
        )
            .then((resp) => {
                // setNotificationData(resp.data)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {

            })
    }

    const searchVideo = async (keywords, sortOpt) => {
        //Check if there are any previous pending requests
        if ((cancelToken !== null) && (typeof cancelToken != typeof undefined)) {
            cancelToken.cancel("Operation canceled due to new request.")
        }
        //Save the cancel token for the current request
        cancelToken = axios.CancelToken.source()

        const searchParams = (keywords.length > 0) ? "&search_query=" + keywords : ""
    
        try {
            const response = await axios.get(
                API_URL + "?sort=" + sortOpt + searchParams,
                { cancelToken: cancelToken.token } // Pass the cancel token to the current request
            )

            // console.log("Results for " + searchParams + ": ", )
            return response.data
        } catch (error) {
            console.log(error)
        } finally {
            cancelToken = null
        }
        return null
    }

    const listVideo = (keywords="", sort="latest") => {
        // searchVideo(keywords).then((results) => {
        //     if (results !== null) dispatch(video.setVideo(results))
        // })

        dispatch(video.list({ keywords, sort }))
    }

    const onSearchChange = (e) => {
        const value = e.currentTarget.value.toLowerCase()
        setKeywords(value)
    }

    const onSearch = (value) => {
        // dispatch(video.list({keywords: value}))
        listVideo(keywords, sortOpt)
    }

    const onSortChange = (value) => {
        setSortOpt(value)
    }

    const refreshData = () => {
        listVideo(keywords, sortOpt)
    }

    useEffect(() => {
        if ((keywords.length === 0) || (keywords.length >= 3))  {
            listVideo(keywords, sortOpt)
        }
    }, [keywords, sortOpt])


    useEffect(() => {
        if (user !== null) {
            let mine = videos.slice(0)
            let others = videos.slice(0)
            
            mine = mine.filter((el) => el.user.uuid === user.uuid)
            others = others.filter((el) => el.user.uuid !== user.uuid)
    
            setShownVideos({ mine, others })
        }
    }, [videos, user])

    const onChange = (key) => {
        // console.log(key);
    }
    
    return(
        <Layout className="px-4">
            <div className="flex flex-col justify-between items-center mt-4 md:flex-row gap-2">
                <div className=" font-semibold">Sort:
                    <Select
                        defaultValue={DEFAULT_SORT_OPT}
                        style={{width: 150}}
                        onChange={onSortChange}
                        className="ml-4"
                    >
                        <Select.Option value="popular">Most Popular</Select.Option>
                        <Select.Option value="latest">Latest</Select.Option>
                        <Select.Option value="oldest">Oldest</Select.Option>
                    </Select>
                </div>
                <Search
                    size="large"
                    placeholder="Search"
                    allowClear
                    onChange={onSearchChange}
                    onSearch={onSearch}
                    className=" shadow-sm w-1/2 md:w-1/3"
                />
                <Button type="primary" htmlType="submit" className="bg-primaryBlue h-13" onClick={showModal}>
                    <div className="flex flex-row justify-center items-center px-1">
                        <Image
                            src={uploadLogo}
                            width={31}
                            preview={false}
                        />
                        <div className="pl-2 text-uploadVideoButton">Upload Video</div>
                    </div>
                </Button>
                <UploadVideoModal open={isModalOpen} onOk={handleModalOk} onCancel={handleModalCancel}/>
            </div>
            <Tabs
                className="mt-4"
                defaultActiveKey="1"
                items={[
                    {
                        key: '1',
                        label: `Shared With Me`,
                        children: <ListMyVideo
                            videos={shownVideos.others}
                            refreshData={refreshData}
                        />,
                    },
                    {
                        key: '2',
                        label: `My Videos`,
                        children: <ListMyVideo
                            videos={shownVideos.mine}
                            refreshData={refreshData}
                        />,
                    },
                ]}
                onChange={onChange}
            />
        </Layout>
    )
} 