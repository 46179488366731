const epochToJsDate = (ts) => {
        // ts = epoch timestamp
        // returns date obj
        return new Date(ts*1000);
}

const jsDateToEpoch = (d) => {
    // d = javascript date obj
    // returns epoch timestamp
    return (d.getTime()-d.getMilliseconds())/1000;
}

const getDateString = (ts) => {
    if (ts === null) return ''

    const dateObj = new Date(Date.parse(ts))
    const YY = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(dateObj);
    const MM = new Intl.DateTimeFormat('en', { month: 'short' }).format(dateObj);
    const DD = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(dateObj);

    const hh = dateObj.getHours().toString().padStart(2, '0');
    const mm = dateObj.getMinutes().toString().padStart(2, '0');
    // const ss = dateObj.getSeconds().toString().padStart(2, '0');
    // return (`${DD} ${MM} ${YY} ${hh}:${mm}:${ss}`)
    return (`${DD} ${MM} ${YY} ${hh}:${mm}`)
}

const getTimeBetweenDates = (startDate, endDate) => {
  const seconds = Math.floor((endDate - startDate) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  return { seconds, minutes, hours, days };
};

const DateHelper = {
    epochToJsDate,
    jsDateToEpoch,
    getDateString,
    getTimeBetweenDates,
}

export default DateHelper;