import React, { useEffect, useState } from "react";
import Input from "antd/es/input/Input";
import { Tabs } from "antd";

import Sidebar from "../components/Sidebar";

import * as video from "../store/video";
import { useDispatch, useSelector } from "react-redux";
import ManagementPanel from "../components/management"
import Layout from "./Layout";

const UserMenu = [
    {
        key: '1',
        label: `Coach Video Management`,
        children: <ManagementPanel.VideoManagement />,
    },
]
export default function Video(){
    const { user } = useSelector((state) => state.auth);
    const [userAdminMenu, setUserAdminMenu] = useState(UserMenu);

    useEffect(() => {
        let adminMenu = [
            {
                key: '2',
                label: `Tag Management`,
                children: <ManagementPanel.TagManagement />,
            },
            {
                key: '3',
                label: `User List`,
                children: <ManagementPanel.UserManagement />,
            },
            {
                key: '4',
                label: `Video Storage`,
                children: <ManagementPanel.StorageManagement />,
            }
        ]

        if ((user !== null) && (user.role === 1)) {
            setUserAdminMenu([
                ...UserMenu,
                ...adminMenu
            ])
        }
        else {
            setUserAdminMenu(UserMenu)
        }
    }, [user])

    return(
        <Layout className="px-4">
            <span className="mt-4 text-xl font-medium">Admin Panel</span>
            <div className=" container flex flex-col min-h-screen">
                <Tabs
                    className="mt-4"
                    defaultActiveKey="1"
                    items={userAdminMenu}
                    onChange={() => {}}
                />
            </div>
        </Layout>
    )
} 