import { configureStore } from '@reduxjs/toolkit' 
import { combineReducers } from 'redux'

import auth from "./auth";
import message from "./message";
import video from "./video";


const reducer = combineReducers({
    auth,
    message,
    video,
})


const store = configureStore({ 
    reducer,
}) 

export default store;