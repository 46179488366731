import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';


import * as auth from "../store/auth";

export default function Logout(){
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(auth.logout())
    }, [])

    return(

        <div className="flex-row flex min-h-screen">
            <div className="w-full text-center">
                <h1 className="mt-16 md:mt-32 p-8 text-6xl">
                    You have been logged out
                </h1>
                <p className="text-lg mb-12">
                    Please re-login from SLS
                </p>
            </div>
        </div>
    )
} 