import { Popconfirm } from 'antd'
import { useSelector } from 'react-redux';
import DateHelper from '../helpers/date'

const CommentCard = (props) => {
    const { user } = useSelector((state) => state.auth);
    const { data } = props

    const handleReplyComment = (e, commentId) => {
        props.handleReply && props.handleReply(e, commentId)
    }

    const handleDelete = (e, commentId) => {
        props.handleDelete && props.handleDelete(e, commentId)
    }

    return (
        <div className="flex items-start w-full mt-2 mb-6">
            <img 
                className={
                    "mt-1 w-6 h-6 rounded-full object-cover mr-3 shadow bg-gray-200 " +
                    (data.user.profile_pic.length > 0 ? "p-0" : "p-2")
                }
                src={data.user.profile_pic.length > 0 ? props.data.user.profile_pic : "/img/user.png"}
                alt="avatar"
            />
            <div className="w-full text-sm">
                <h2 className="text-gray-900">{ data.user.name }</h2>
                <div className="mt-1 flex items-center">
                    <small className="text-xs text-videoCardDate">
                        { DateHelper.getDateString(data.created_at_iso8601) }
                    </small>
                </div>
                {
                data.tags.length > 0 &&
                <div className="text-gray-500 text-xs mt-2">
                    {
                        data.tags.map((tag, idx) => (
                            <span key={idx} className={idx === 0? "":"ml-1"}>#{tag.tag}</span>
                        ))
                    }
                </div>
                }
                <div className="mt-1">
                    { data.description }
                    { 
                    (props.data.created_at_iso8601 !== props.data.updated_at_iso8601) &&
                    <span className="ml-1 text-gray-400">(edited)</span>
                    }
                </div>
                <div className="flex mt-2 text-xs text-gray-600">
                    <div className="mr-4 cursor-pointer hover:text-activeBlue"
                        onClick={(e) => { handleReplyComment(e, data.id) }}
                    >
                        Reply
                    </div>

                    {
                    (data.user.uuid === user.uuid) &&
                    <>
                    <div 
                        className="mr-2 cursor-pointer hover:text-activeBlue"
                        onClick={(e) => { props.handleEdit(e, data) }}
                    >
                        Edit
                    </div>
                    <Popconfirm
                        title="Delete the comment"
                        description="Are you sure to delete this comment?"
                        onConfirm={(e) => { handleDelete(e, data.id) }}
                        okButtonProps={{ className: "bg-activeBlue"}}
                        okText="Yes"
                        cancelText="No"
                    >
                        <div className="cursor-pointer hover:text-activeBlue"
                        >
                            Delete
                        </div>
                    </Popconfirm>
                    </>
                    }
                </div>
            </div>
        </div>
    )
}

const CommentBox = (props) => {
    return (
        <div className={props.className}>
            <CommentCard 
                data={props.data}
                handleReply={props.handleReply}
                handleDelete={props.handleDelete}
                handleEdit={props.handleEdit}
            />
            {
            props.data && props.data.children &&
            <div className="border-l">
            {
                props.data.children.map((cmt, idx) => (
                <CommentBox
                    key={idx}
                    className="ml-4"
                    data={cmt}
                    handleReply={props.handleReply}
                    handleDelete={props.handleDelete}
                    handleEdit={props.handleEdit}
                />
            ))
            }
            </div>
            }
        </div>
    )
}

export default CommentBox;