import { FlagFilled, FlagOutlined } from "@ant-design/icons";
import { Popconfirm } from "antd";
import { useSelector } from "react-redux";
import DateHelper from "../helpers/date";

const PostCard = (props) => {
    const { user } = useSelector((state) => state.auth);
    
    const getTimeString = (ts) => {
        const mm = parseInt(Math.floor(ts / 60));
        const ss = ts % 60;

        return mm.toString().padStart(2, '0') + ":" + ss.toString().padStart(2, '0')
    }

    const handleFlag = (e) => {
        e.stopPropagation()
        props.handleFlag && props.handleFlag(e, props.data.id, props.data.status === 0)
    }

    const handleReply = (e) => {
        e.stopPropagation()
        props.handleReply && props.handleReply(e, props.data.id)
    }

    const handleDelete = (e) => {
        props.handleDelete && props.handleDelete(e, props.data.id)
    }

    const showComments = (e) => {
        e.stopPropagation()
        props.showComments && props.showComments(e, props.data.id)
    }

    const onClick = (e) => {
        props.onClick && props.onClick(e, props.data)
    }

    return (
        <div className="flex bg-white shadow-lg rounded-lg md:mx-auto w-full mb-6"
             onClick={onClick}
        >
            <div className="flex items-start px-4 py-6 w-full">
            <img
                className={
                    "w-10 h-10 rounded-full object-cover mr-3 shadow bg-gray-200 " +
                    (props.data.user.profile_pic.length > 0 ? "p-0" : "p-2")
                }
                src={props.data.user.profile_pic.length > 0 ? props.data.user.profile_pic : "/img/user.png"}
                alt="avatar"
            />
            
            <div className="w-full">
                <div className="flex items-start justify-between">
                    <div className="flex flex-col">
                        {
                        props.data.tags.length > 0 &&
                        <div className="text-gray-800 text-sm font-medium mb-2">
                            {
                                props.data.tags.map((tag, idx) => (
                                    <span key={idx} className={idx === 0? "":"ml-1"}>#{tag.tag}</span>
                                ))
                            }
                        </div>
                        }
                        <h2 className="text-lg font-semibold text-gray-900">{ props.data.user.name }</h2>
                    </div>
                    <small className="cursor-pointer text-sm font-medium text-primaryBlue">{ getTimeString(props.data.time) }</small>
                </div>
                <div className="flex items-center">
                    <small className="text-sm text-videoCardDate">Posted at { DateHelper.getDateString(props.data.created_at_iso8601) }</small>
                </div>
                <p className="mt-3 text-gray-700 text-sm">
                    { props.data.description }
                    { 
                    (props.data.created_at_iso8601 !== props.data.updated_at_iso8601) &&
                    <span className="ml-1 text-gray-400">(edited)</span>
                    }
                </p>
                <div className="mt-4 flex items-center">
                    <div className={"cursor-pointer mr-4 -mt-3" + (props.data.status === 1 ? " text-red-500" : "") }
                        onClick={handleFlag}
                    >
                        { props.data.status === 0 ? <FlagOutlined /> : <FlagFilled /> }
                    </div>

                    {
                    (props.data && props.data.comments) &&
                    <div 
                        className={"flex mr-2 text-sm cursor-pointer" + (props.data.comments.length > 0 ? " text-activeBlue font-bold" : " text-gray-700")}
                        onClick={showComments}>
                        <svg fill="none" viewBox="0 0 24 24" className="w-4 h-4 mr-1" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"/>
                        </svg>
                        <span>{ props.data.totalComments }</span>
                    </div>
                    }

                    <div className="flex mr-2 text-gray-700 text-sm">
                        <span className="cursor-pointer ml-2 mr-4"
                            onClick={handleReply}
                        >Reply</span>
                        {
                        (props.data.user.uuid === user.uuid) &&
                        <>
                        <div 
                            className="mr-2 cursor-pointer hover:text-activeBlue"
                            onClick={
                                (e) => {
                                    e.stopPropagation()
                                    props.handleEdit(props.data)
                                }
                            }
                        >
                            Edit
                        </div>
                        <div onClick={(e) => e.stopPropagation()}>
                        <Popconfirm
                            title="Delete the annotation"
                            description="Are you sure to delete this annotation? All replies will also be deleted."
                            onConfirm={handleDelete}
                            okButtonProps={{ className: "bg-activeBlue"}}
                            okText="Yes"
                            cancelText="No"
                        >
                            <div className="text-sm text-gray-700 cursor-pointer hover:text-activeBlue"
                            >
                                Delete
                            </div>
                        </Popconfirm>
                        </div>
                        </>
                        }
                        
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default PostCard;