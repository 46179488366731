import React, { useState, useEffect, useCallback } from "react";

import "../App.css";

import {Link, useParams, useLocation, useNavigate} from "react-router-dom";
import Layout from "./Layout";
import AnalyticsCard from "../components/analytics"
import { useDispatch, useSelector } from "react-redux";

import * as video from "../store/video";
import {LeftOutlined} from "@ant-design/icons";
import {Button, Input, Modal} from "antd";
import axios from "axios";
import {BASE_URL} from "../services/server";
import TreeHelper from "../helpers/tree";
import {IS_LADDER} from "../helpers/config";
import LadderAnalyticsHelper from "../helpers/analytics";
import history from '../helpers/history';

const LadderDashboard = (props) => {
    let { videoId } = useParams();
    const dispatch = useDispatch();
    const [ loading, setLoading ] = useState(true)
    const [ dashboardType, setDashboardType ] = useState(0)
    const [ isReflectionOpen, setIsReflectionOpen] = useState(false)
    const [ reflection1, setReflections1] = useState('')
    const [ reflection2, setReflections2] = useState('')
    const [ reflection3, setReflections3] = useState('')
    const [ selectedVideo, setSelectedVideo ] = useState(null);
    const locationState = useLocation()

    const [showExitDialog, setShowExitDialog] = useState(false)

    const { user } = useSelector((state) => state.auth);

    const [triggerExit, setTriggerExit] = useState({
        onOk: false,
        path: ""
    });

    const navigate = useNavigate();
    const handleGoToIntendedPage = useCallback(
        (location) => navigate(location),
        [navigate]
    );

    useEffect(() => {
        if (triggerExit.onOk) {
          handleGoToIntendedPage(triggerExit.path);
        }

        const unblock = history.block(({ location }) => { // <-- block callback
          if (location.pathname !== "/testing") {
              setShowExitDialog(true);
          }

          setTriggerExit((obj) => ({ ...obj, path: location.pathname }));
          if (triggerExit.onOk) {
            return true;
          }
          return false;
        });

        return () => {
          unblock();
        };
      }, [handleGoToIntendedPage, navigate, triggerExit.onOk, triggerExit.path]);


    useEffect(() => {
        axios.get(BASE_URL + 'ladder/videos/' + videoId)
            .then((res) => {
                if (res.status !== 200) return ;

                let comments = res.data.comments
                let flatComments = res.data.comments ? res.data.comments.slice(0) : []

                try {
                    comments = TreeHelper.arrayToTree(res.data.comments)
                }
                catch (err) {}

                let timeline = []
                if (res.data.timeline && (res.data.timeline.length > 0)) {
                    const jsonTimeline = JSON.parse(res.data.timeline[0].state.replace(/'/g, '"'))
                    timeline = jsonTimeline
                }

                setSelectedVideo({
                    ...res.data,
                    comments,
                    flatComments,
                    timeline
                })
        })
    }, [dispatch, videoId])

    useEffect(() => {
        let currentDashboard = -1

        if (locationState.state) {
            try {
                currentDashboard = parseInt(locationState.state.dashboard)
                if (isNaN(currentDashboard)) currentDashboard = -1
            }
            catch (err) {
                console.log(err)
            }

            if (locationState.state.experiment) {
                getDashboardData(locationState.state.experiment)
            }
        }

        if (currentDashboard === -1) {
            if (user && user.rid && (user.rid.length > 0)) {
                currentDashboard = parseInt(user.rid[0])
            }
            else currentDashboard = 0
        }

        setDashboardType(currentDashboard)
    }, [locationState.state, user])

    let pending = true;

    useEffect(() => {
        const unloadCallback = (e) => {
            e.preventDefault();

            // console.log('111')
            if (!pending) return;

            e.preventDefault();
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => {
            window.removeEventListener("beforeunload", unloadCallback);
        }
    }, [pending]);

    const getDashboardData = (xpId) => {
        axios.get(BASE_URL + 'xp-admin/experiments/' + xpId)
            .then((res) => {
                let comments = res.data.comments
                let flatComments = res.data.comments.slice(0)

                try {
                    comments = TreeHelper.arrayToTree(res.data.comments)
                }
                catch (err) {}

                let timeline = null
                if (res.data.timeline.length > 0) {
                    const jsonTimeline = JSON.parse(res.data.timeline[0].state.replace(/'/g, '"'))
                    timeline = jsonTimeline
                }

                setSelectedVideo({
                    ...res.data,
                    comments,
                    flatComments,
                    timeline
                })
        })
    }

    const saveReflection = () => {
        axios.post(BASE_URL + `ladder/reflection/${videoId}`,
            {
                answers:[
                    {
                       answer: reflection1
                    },
                    {
                       answer: reflection2
                    },
                    {
                       answer: reflection3
                    }
               ]}
            )
            .then((res) => {
                setIsReflectionOpen(false)
            })

        let eventData = {
            type: 'Reflection_Goal_Add',
            answers: [reflection1, reflection2, reflection3]
        }
        const video_data = { id: selectedVideo.id }

        LadderAnalyticsHelper.sendEvent(eventData, video_data)

        setIsReflectionOpen(true)
    }

    const sendAnalyticsExitConfirmation = (isOk = true) => {
        let eventData = {
            type: 'Dashboard_Confirmation_Button_' + (isOk ? "Yes" : "No"),
        }

        const video_data = {
            id: selectedVideo.id,
            title: selectedVideo.title,
        }

        LadderAnalyticsHelper.sendEvent(eventData, video_data)
    }

    const sendAnalyticsDashboard = () => {
        let eventData = {
            type: 'Dashboard_Back_Button_Clicked',
        }

        const video_data = {
            id: selectedVideo.id,
            title: selectedVideo.title,
        }

        LadderAnalyticsHelper.sendEvent(eventData, video_data)
    }

    const onChartMouseEnter = (dashboardId= 0, isInfo= false) => {
        let eventData = {
            type: (isInfo ? 'Info_Button_Chart' : 'Dashboard_Chart') + dashboardId + '_Enter',
        }

        const video_data = {
            id: selectedVideo.id,
        }

        LadderAnalyticsHelper.sendEvent(eventData, video_data)
    }

    const onChartMouseLeave = (dashboardId, isInfo= false) => {
        let eventData = {
            type: (isInfo ? 'Info_Button_Chart' : 'Dashboard_Chart') + dashboardId + '_Leave',
        }

        const video_data = {
            id: selectedVideo.id,
        }

        LadderAnalyticsHelper.sendEvent(eventData, video_data)
    }

    const handleReflect = () => {
        let eventData = { type: 'Button_Reflection_Goal_Clicked' }
        const video_data = { id: selectedVideo.id }

        LadderAnalyticsHelper.sendEvent(eventData, video_data)

        setIsReflectionOpen(true)
    }

    const videoTitle =  (selectedVideo !== null) ? selectedVideo.title : ""

    return(
        <Layout className="px-4">
            {/*
            blocker.state === "blocked" ? (
                <div>
                    <p>Are you sure you want to leave?</p>
                    <button onClick={() => blocker.proceed()}>
                        Proceed
                    </button>
                    <button onClick={() => blocker.reset()}>
                        Cancel
                    </button>
                </div>
            ) : null
            */}
            <div className="flex flex-col w-full font-medium text-lg">
                <div className="mt-2">
                <Link title={videoTitle}
                      to={(locationState.state && locationState.state.src) ?
                        locationState.state.src :
                        (locationState.pathname.toLowerCase().includes('ladder')
                          ? locationState.pathname.toLowerCase().replace('dashboard', 'videos')
                          : '/video/' + videoId)}
                      onClick={sendAnalyticsDashboard}
                >
                    <LeftOutlined className="mr-1" /><span>Back to {(videoTitle && (videoTitle.length > 0)) ? "Video: " + videoTitle : "Admin Panel"}</span>
                </Link>
                </div>
                <span className="mt-2 text-center">My Learning Dashboard - How have I promoted thinking and discussion?</span>
            </div>
            <div className="container w-full mt-4 grid grid-cols-2 gap-4">
                <div className="border border-black rounded-sm pt-2 h-80">
                    <AnalyticsCard.LadderReplies
                        title="How many annotations/replies have I posted?"
                        data={selectedVideo}
                        dashboard={dashboardType}
                        onMouseEnter={() => { onChartMouseEnter(1) }}
                        onMouseLeave={() => { onChartMouseLeave(1) }}
                        onInfoMouseEnter={() => { onChartMouseEnter(1, true) }}
                        onInfoMouseLeave={() => { onChartMouseLeave(1, true) }}
                    />
                </div>

                <div className="border border-black rounded-sm pt-2 80">
                    <AnalyticsCard.LadderMyTags
                        title="What tags have I applied? (Lesson and other tags)"
                        data={selectedVideo}
                        dashboard={dashboardType}
                        onMouseEnter={() => { onChartMouseEnter(2) }}
                        onMouseLeave={() => { onChartMouseLeave(2) }}
                        onInfoMouseEnter={() => { onChartMouseEnter(2, true) }}
                        onInfoMouseLeave={() => { onChartMouseLeave(2, true) }}
                    />
                </div>
            </div>
            <div className="container w-full mt-4 grid grid-cols-2 gap-4">
                <div className="border border-black rounded-sm pt-2 h-80">
                    <AnalyticsCard.LadderCloud
                        title="What words have I used in posts?"
                        data={selectedVideo}
                        dashboard={dashboardType}
                        onMouseEnter={() => { onChartMouseEnter(3) }}
                        onMouseLeave={() => { onChartMouseLeave(3) }}
                        onInfoMouseEnter={() => { onChartMouseEnter(3, true) }}
                        onInfoMouseLeave={() => { onChartMouseLeave(3, true) }}
                    />
                </div>
                <div className="border border-black rounded-sm pt-2 h-80">
                    <AnalyticsCard.LadderPosted
                        title="Who have I replied to? Who have I not replied to?"
                        data={selectedVideo}
                        dashboard={dashboardType}
                        onMouseEnter={() => { onChartMouseEnter(4) }}
                        onMouseLeave={() => { onChartMouseLeave(4) }}
                        onInfoMouseEnter={() => { onChartMouseEnter(4) }}
                        onInfoMouseLeave={() => { onChartMouseLeave(4) }}
                    />
                </div>
            </div>

            {
            ((dashboardType === 4) || (dashboardType === 5)) &&
            <div className="container w-full flex justify-center">
                <Button className="fixed bottom-2 bg-activeBlue text-white hover:bg-blue-200"
                    onClick={handleReflect}
                >
                    Reflect and Set Goals
                </Button>
            </div>
            }
            <Modal
                title="Reflect and Set Goals"
                centered
                open={isReflectionOpen}
                onOk={saveReflection}
                onCancel={() => setIsReflectionOpen(false)}
                destroyOnClose={true}
                closable={false}
                width="70%"
                style={{
                    body: {height: "500px"}
                }}
                okButtonProps={{ className: "bg-activeBlue"}}
                okText="Save"
            >
                <div className="w-full flex flex-col px-2 align-center items-center">
                    <div className="flex flex-col w-full mb-2">
                        <p>
                            1. What did the dashboard information tell me about my thinking and discussion?
                        </p>
                        <Input className="flex-1 text-base py-2"
                            value={reflection1}
                            onChange={e => setReflections1(e.currentTarget.value)}
                        />
                    </div>
                    <div className="flex flex-col w-full mb-2">
                        <p>
                            2. What can I improve on? Think about what you can change and/or how others can help you.
                        </p>
                        <Input className="flex-1 text-base py-2"
                            value={reflection2}
                            onChange={e => setReflections2(e.currentTarget.value)}
                        />
                    </div>
                    <div className="flex flex-col w-full mb-2">
                        <p>
                            3. Any other reflections?
                        </p>
                        <Input className="flex-1 text-base py-2"
                            value={reflection3}
                            onChange={e => setReflections3(e.currentTarget.value)}
                        />
                    </div>
                </div>
            </Modal>

            <Modal
                title=" "
                centered
                open={showExitDialog}
                onOk={() => {
                    sendAnalyticsExitConfirmation(true)
                    setTriggerExit((obj) => ({
                        ...obj,
                        onOk: true
                    }));
                    setShowExitDialog(false);
                }}
                onCancel={() => {
                    sendAnalyticsExitConfirmation(false)
                    setShowExitDialog(false)
                }}
                okButtonProps={{ className: "bg-activeBlue"}}
                okText="Yes"
                cancelText="No, I will interact."
                width="50%"
            >
                <div className="w-full flex flex-row px-2 align-center items-center">
                    <p className="text-lg w-full">Have you interacted with your dashboard?</p>
                </div>
            </Modal>
        </Layout>
    )
}

export default LadderDashboard