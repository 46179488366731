import axios from "axios";

import { BASE_URL } from "./server"

export const API_URL = BASE_URL + "video";

const upload = (formData) => {
    return axios.post(API_URL + "/upload", 
        formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    .then((response) => {
        if (response.data.access_token) {
        }

        return response.data;
    });
};

const create = (title, duration, thumbnail, description, video) => {
    return axios.post(API_URL, {
        title,
        duration,
        thumbnail,
        description,
        type: "s3",
        video
    })
    .then((response) => {
        
        return response.data;
    });
};

const list = (keywords="", sort) => {
    const searchParams = (keywords.length > 0) ? "&search_query=" + keywords : ""
    return axios.get(API_URL + "?sort_opt=" + sort + searchParams)
        .then((response) => {
            return response.data;
        });
};


const get = (videoId) => {
    return axios.get(API_URL + "/" + videoId)
        .then((response) => {
            return response.data;
        });
};

const remove = (videoId) => {
    return axios.delete(API_URL + videoId)
        .then((response) => {
            
            return response.data;
        });
};


const update = (title, duration, thumbnail, description, video) => {
    return axios.put(API_URL + "", {
        title,
        duration,
        thumbnail,
        description,
        type: "s3",
        video
    })
    .then((response) => {
        if (response.data.access_token) {
        }

        return response.data;
    });
};

const videoService = {
    create,
    list,
    upload,
    get,
    remove,
    update,
};

export default videoService;