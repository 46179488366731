import React, { useEffect, useRef, useState } from "react";
import {Link, NavLink} from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import axios from "axios";
import {BASE_URL} from "../services/server";
import {Avatar, Dropdown, Image, Space} from "antd";
import covaa_logo from "../assets/covaa_logo.png";
import {LogoutOutlined, UserOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import sidebar_admin from "../assets/sidebar_admin.svg";

dayjs.extend(relativeTime)

const Topbar = (props) => {
    const [showList, setShowList] = useState(false)
    const [notifications, setNotifications] = useState([])
    const [notificationLoading, setNotificationLoading] = useState(false)
    const wrapperRef = useRef(null);
    const triggerRef = useRef(null);

    const { user } = useSelector((state) => state.auth);

    // const linkUserGuide = "/doc/CoVAAPD-UserGuide.pdf"
    const linkUserGuide = "https://entuedu-my.sharepoint.com/personal/prjladder2_niestaff_cluster_nie_edu_sg/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fprjladder2%5Fniestaff%5Fcluster%5Fnie%5Fedu%5Fsg%2FDocuments%2FCoVAA%2FCoVAAUserGuide%5F15Feb24%2Epdf&parent=%2Fpersonal%2Fprjladder2%5Fniestaff%5Fcluster%5Fnie%5Fedu%5Fsg%2FDocuments%2FCoVAA&ga=1"

    const getNotifications = () => {
        setNotificationLoading(true)

        axios.get(BASE_URL + 'user/notifications')
            .then((resp) => {
                setNotifications(resp.data.items)
            }).finally(() => {
                setNotificationLoading(false)
            })
    }

    useEffect(() => {
        getNotifications()
    }, [])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && triggerRef.current &&
                !(
                    wrapperRef.current.contains(event.target) ||
                    triggerRef.current.contains(event.target)
                )
            ) {
                if (showList) setShowList(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef, triggerRef]);

    const toggleNotificationList = () => {
        setShowList(!showList)
    }

    const markRead = (idx) => {
        axios.post(BASE_URL + 'user/notifications/' + idx + '/read')
    }

    return(
        <div
            className="relative flex w-full bg-white text-right border py-0 px-2 z-10">
            <div className="flex h-16 py-2 flex-1 items-center">
                <img
                    className="h-full responsive"
                    src={covaa_logo}
                />
                {
                user && (user.role < 5) &&
                <NavLink
                    to="/ladder-admin"
                >
                    <div className="flex flex-row justify-center items-center pr-6 text-activeBlue">
                        <Image
                            src={sidebar_admin}
                            width={22}
                            preview={false}
                            className="text-activeBlue"
                        />
                        <div className=" text-sidebarNavigation ml-2">Ladder Admin</div>
                    </div>
                </NavLink>
                }
            </div>
            <div className="flex w-1/2 items-center justify-end">
                <div className="flex flex-row justify-center items-center p-4 rounded-lg">
                    <Space>
                        {
                        user && user.profile_pic && user.profile_pic.length > 0 ?
                        <div className="flex align-center justify-center h-6 w-6 rounded-full overflow-hidden">
                            <img src={user.profile_pic} alt={user.name} />
                        </div>
                        :
                        <Avatar size={28} icon={<UserOutlined />}
                            className="flex justify-center items-center"
                        />
                        }
                    </Space>
                    <div className="px-2 border-r-2 border-gray-600">{ user ? user.name : ""}</div>
                </div>
                <Link to={linkUserGuide} target="_blank" rel="noopener noreferrer"
                      className="relative inline-flex w-fit mr-4"
                >
                    <div className="text-gray-600 hover:text-activeBlue flex flex-row justify-center items-center cursor-pointer">
                        <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path
                                d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                        </svg>
                    </div>
                </Link>
                <a rel="noopener noreferrer" href="/logout" className="hover:text-activeBlue">
                    <LogoutOutlined />
                    <span className="ml-1">Logout</span>
                </a>
            </div>
        </div>
    )
}

export default Topbar;
