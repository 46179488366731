const ModalAnnotationAlert = (props) => {
    const closeModal = () => {
        props.onClose && props.onClose();
    }
    
    if (!props.show) return null;

    return (
            <div className="py-32 transition duration-150 ease-in-out z-10 absolute top-0 right-0 bottom-0 left-0" id="modal" 
                style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}>
                <div role="alert" className="container mx-auto w-11/12 md:w-2/3 max-w-xl">
                    <div className="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
                        <div>
                            There is an annotation here, please add comment to the existing annotation.
                            <button 
                                className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600" 
                                onClick={closeModal} aria-label="close modal"
                            >
                                <svg  xmlns="http://www.w3.org/2000/svg"  className="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" />
                                    <line x1="18" y1="6" x2="6" y2="18" />
                                    <line x1="6" y1="6" x2="18" y2="18" />
                                </svg>
                            </button>
                        </div>
                        <div className="mt-8 flex items-center justify-center w-full">
                            <button 
                                className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-sm" 
                                onClick={closeModal}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default ModalAnnotationAlert;