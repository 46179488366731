import {useEffect, useState} from "react";
import {Button, Input, message, Popconfirm, Space, Switch, Table} from 'antd'
import axios from "axios";
import {BASE_URL} from "../../services/server";
import {useNavigate} from "react-router-dom";
import DateHelper from "../../helpers/date";

const { Column } = Table;

const UserManagement = (props) => {
    const [data, setData] = useState({
        total_items: 0,
        items_per_page: 10,
        page: 1,
        total_pages: 1
    })

    const [users, setUsers] = useState([])
    const [shownSharedPanel, setShownSharedPanel] = useState(false)
    const [sharedUsers, setSharedUsers] = useState([])
    const [selectedTags, setSelectedTags] = useState([])
    const [videoId, setVideoId] = useState(null)
    const navigate = useNavigate();

    useEffect(() => {
        getUsers(1, 25)
    }, [])

    const getUsers = (page, size) => {
        axios.get(BASE_URL + `user?page=${page}&per_page=${size}`)
            .then((res) => {
                const userItems = res.data.items.map(obj =>
                    ({
                        ...obj,
                        is_sls: obj.sls_id !== null
                    })
                );
                setUsers(userItems)
                setData(res.data)
            })
    }

    const setAdmin = (userId) => {
        axios.post(BASE_URL + `user/${userId}/admin`)
            .then((res) => {
                getUsers(1, 25)
            })
    }

    const removeAdmin = (userId) => {
        axios.delete(BASE_URL + `user/${userId}/admin`)
            .then((res) => {
                getUsers(1, 25)
            })
    }


    const manageVideoTag = (e) => {
    }

    return (
    <div className="">
        <Table dataSource={users} rowKey="uuid"
               pagination={{
                   position: ["topRight", "bottomRight"],
                   current: data.page,
                   total: data.total_items,
                   pageSize: data.items_per_page,
                   showSizeChanger: true,
                   pageSizeOptions: [5, 10, 25, 50, 100],
                   showTotal: (total, range) => `Total users: ${total}`,
                   onChange: (page, size) => {
                       getUsers(page, size)
                   }
               }}
        >
            <Column
                title="Name"
                width="20%" dataIndex="name" key="name"
                sorter={(a, b) => a.name.localeCompare(b.name)}
            />
            <Column
                title="Email" dataIndex="email" key="email"
                sorter={(a, b) => a.email.localeCompare(b.email)}
            />
            <Column
                title="Role"
                sorter={(a, b) => a.role - b.role}
                render={(_, record) => (
                    <Space size="middle">
                        {
                            record.role === 0 ? "Super Admin" :
                                record.role === 1 ? "Admin" :
                                    record.role === 2 ? "Sub-Admin" :
                                        record.role === 3 ? "Normal User" : "N/A"
                        }
                    </Space>
                )}
            />

            <Column title="School"
                sorter={(a, b) => {
                    const a_school = (a.school !== null) && (a.school.name !== null) ?
                        a.school.name : "ZZ"
                    const b_school = (b.school !== null) && (b.school.name !== null) ?
                        b.school.name : "ZZ"
                    return a_school.localeCompare(b_school)
                }}
                render={(_, record) => (
                    <Space size="middle" className="">
                        {
                            (
                                (record.school !== null) &&
                                (record.school.name !== null)
                            ) ? record.school.name : ''
                        }
                    </Space>
                )}
            />
            <Column title="First Login"
                render={(_, record) => (
                    <Space size="middle">
                        {
                            DateHelper.getDateString(record.registered_on)
                        }
                    </Space>
                )}
            />
            <Column title="Last Login"
                render={(_, record) => (
                    <Space size="middle">
                        {
                            DateHelper.getDateString(record.last_login)
                        }
                    </Space>
                )}
            />
            <Column
                title="Action"
                key="action"
                render={(_, record) => (
                    <Space size="middle">
                    {
                    (record.role === 1) ?
                    <Popconfirm
                        title="Remove Admin"
                        description="Are you sure to remove admin role for this user?"
                        onConfirm={() => { removeAdmin(record.uuid) }}
                        okButtonProps={{ className: "bg-activeBlue"}}
                        okText="Yes"
                        cancelText="No"
                    >
                        <div className="cursor-pointer hover:text-activeBlue"
                        >
                            Remove Admin
                        </div>
                    </Popconfirm>
                    :
                    <Popconfirm
                        title="Make Admin"
                        description="Are you sure to grant admin role to this user?"
                        onConfirm={() => { setAdmin(record.uuid) }}
                        okButtonProps={{ className: "bg-activeBlue"}}
                        okText="Yes"
                        cancelText="No"
                    >
                        <div className="cursor-pointer hover:text-activeBlue"
                        >
                            Make Admin
                        </div>
                    </Popconfirm>
                    }
                    </Space>
                )}
            />
        </Table>
    </div>
    )
}

export default UserManagement