import {
    ArcElement,
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {Bar, Pie} from "react-chartjs-2";
import zoomPlugin from 'chartjs-plugin-zoom';
import {Button, Popover, Select} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import {IS_LADDER} from "../../helpers/config";
import LadderAnalyticsHelper from "../../helpers/analytics";

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
);

const options = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    scales: {
        y: {
            min: 0,
            ticks: {
                stepSize: 1,
            }
        },
        x: {
            ticks: {
                stepSize: 1,
                autoSkip: false,
                maxRotation: 45,
                minRotation: 45
            }
        }
    },
    plugins: {
        legend: {
            display: false
        },
        datalabels: {
            labels: {
                title: null
            }
        },
    }
};

const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false
        },
    }
};

const LadderPosted = (props) => {const [loading, setLoading] = useState(true)
    const { user } = useSelector((state) => state.auth);
    const [chartData, setChartData] = useState([])
    const [dashboardType, setDashboardType] = useState(0)
    const [representation, setRepresentation] = useState('bar')
    const [repliedParticipants, setRepliedParticipants] = useState({
            data: [],
            text: ''
        })
    const [unrepliedParticipants, setUnrepliedParticipants] = useState({
            data: [],
            text: ''
        })
    const [average, setAverage] = useState({
        replied: 0,
        unreplied: 0
    })

    const LegendText =
        <div className="text-sm mx-2 max-w-xs">
            <p>Have you replied to different students?</p>
            <p className="mt-2">The more students you reply to, the more you learn from others.</p>
        </div>

    const LegendTextPeer =
        <div className="text-sm mx-2 max-w-xs">
            <p>Have you replied to different students?</p>
            <p className="mt-2">The more students you reply to, the more you learn from others.</p>
            <p className="mt-2">The class average is the average number of students your class has replied to.</p>
        </div>


    useEffect(() => {
        setDashboardType(props.dashboard ? props.dashboard: 0)
    }, [props.dashboard])

    useEffect(() => {
        if ((props.data !== null) && (user !== null)) {
            let labels = []
            let myDataValues = []

            let repliedAnnList = []
            props.data.flatComments.forEach((cmt) => {
                const isMine = (cmt.user.uuid === user.uuid)

                if (isMine) repliedAnnList.push(cmt.annotation_id)
            })

            const repliedAnn = props.data.annotations.filter(ann => repliedAnnList.includes(ann.id))
            const unrepliedAnn = props.data.annotations.filter(ann => !repliedAnnList.includes(ann.id))

            let repliedUser = []
            let unrepliedUser = []
            let uniqueRepliedUser = {}
            let uniqueRepliedUserIdList = []
            let uniqueUnrepliedUser = {}

            repliedAnn.forEach(ann => {
                if (!repliedUser.includes(ann.user.uuid)) {
                    repliedUser.push(ann.user.uuid)

                    uniqueRepliedUser[ann.user.uuid] = {
                        name: ann.user.name,
                        count: repliedAnnList.filter(rAnn => rAnn === ann.id).length
                    }
                }
                else {
                    uniqueRepliedUser[ann.user.uuid].count += repliedAnnList.filter(rAnn => rAnn === ann.id).length
                }
            })

            unrepliedAnn.forEach(ann => {
                if (!repliedUser.includes(ann.user.uuid)) {
                    if (!unrepliedUser.includes(ann.user.uuid)) {
                        unrepliedUser.push(ann.user.uuid)
                        uniqueUnrepliedUser[ann.user.uuid] = {
                            name: ann.user.name,
                            count: 1
                        }
                    }
                    else {
                        uniqueUnrepliedUser[ann.user.uuid].count += 1
                    }
                }
            })

            let strRepliedUser = []
            let repliedData = []
            let strUnrepliedUser = []
            let unrepliedData = []

            let totalUniqueReplied = 0
            let totalUniqueUnreplied = 0

            Object.entries(uniqueRepliedUser).map(([uId, uData]) => {
                if (uId !== user.uuid) {
                    strRepliedUser.push(uData.name + ' (' + uData.count + ' repl' + (uData.count > 1 ? 'ies' : 'y') + ')')
                    repliedData.push(uData)
                    totalUniqueReplied++
                }
            })

            Object.entries(uniqueUnrepliedUser).map(([uId, uData]) => {
                if (uId !== user.uuid) {
                    strUnrepliedUser.push(uData.name)
                    unrepliedData.push(uData)
                    totalUniqueUnreplied++
                }
            })

            setAverage({
                replied: ((1+totalUniqueReplied)/3).toFixed(1),
                unreplied: ((1+2+totalUniqueUnreplied)/3).toFixed(1)
            })

            setRepliedParticipants({
                data: repliedData,
                text: strRepliedUser.length > 0 ? strRepliedUser.join(', ') : '-'
            })

            setUnrepliedParticipants({
                data: unrepliedData,
                text: strUnrepliedUser.length > 0 ? strUnrepliedUser.join(', ') : '-'
            })

            Object.entries(uniqueRepliedUser).forEach(([tId, tData]) => {
                if (tId !== user.uuid) {
                    labels.push(tData.name)
                    myDataValues.push(tData.count)
                }
            })

            Object.entries(uniqueUnrepliedUser).forEach(([tId, tData]) => {
                if (tId !== user.uuid) {
                    labels.push(tData.name)
                    myDataValues.push(0)
                }
            })

            const barColor = myDataValues.length <= myDataValues.filter(dataVal => dataVal > 0).length ?
                ['rgba(55, 196, 161, 1)'] :
                ['rgba(255, 192, 1, 1)']

            setChartData({
                labels,
                datasets: [
                    {
                        label: 'Posted Annotation/Replies',
                        data: myDataValues,
                        backgroundColor: barColor,
                    }
                ],
            })

            setLoading(false)
        }
    }, [props.data, user])

    const onDashboardChange = (value) => {
        let eventData = {
            type: 'Dashboard_Toggle',
        }

        const video_data = {
            id: props.data.id,
            title: props.data.title,
            dashboard: 4,
            target_chart: value
        }

        LadderAnalyticsHelper.sendEvent(eventData, video_data)

        setRepresentation(value)
    }
    if (loading) return <div>Loading</div>

    return(
        <div className="h-full flex-1 flex flex-col items-center justify-center"
             onMouseEnter={props.onMouseEnter}
             onMouseLeave={props.onMouseLeave}
        >
            <h2 className="mb-2 font-bold">
                {props.title}
                <Popover
                    placement="bottomRight"
                     content={(dashboardType !== 1) ? LegendText : LegendTextPeer}
                     className="mx-1 cursor-default">
                    <Button
                         onMouseEnter={props.onInfoMouseEnter}
                         onMouseLeave={props.onInfoMouseLeave}
                         className="bg-activeBlue text-white hover:bg-blue-200">
                        Info
                    </Button>
                </Popover>
            </h2>
            {
                ((dashboardType === 3) || (dashboardType === 5)) &&
                <Select
                    defaultValue="bar"
                    size="small"
                    style={{
                        width: 140,
                    }}
                    onChange={onDashboardChange}
                    options={[
                    {
                        value: 'bar',
                        label: 'Bar Chart',
                    },
                    {
                        value: 'pie',
                        label: 'Pie Chart',
                    },
                    ]}
                />
            }
            <div className="w-full px-4 flex-1 mt-1">
            {
            (chartData.labels.length === 0) ?
                <span className="flex text-gray-600 mt-10">No data to display</span>
            :
            <div className="w-full flex flex-col">
                <div className="w-full h-32 flex">
                {
                ((dashboardType === 3) || (dashboardType === 5))
                && (representation == 'pie') ?
                <>
                    <Pie data={chartData} options={pieOptions} />
                </>
                :
                <Bar
                    data={chartData}
                    options={options}
                />
                }
                </div>
                <div>
                    <div className="flex flex-col mt-4">
                        <div className="flex flex-row font-medium text-xs">
                            <p className="">I replied to {repliedParticipants.data.length} participant(s)</p>
                            {
                            dashboardType === 1 &&
                            <p className="pl-4 text-primaryBlue">Class average: {average.replied}</p>
                            }
                        </div>
                        <p className="font-medium text-xs">I replied most to</p>
                        <p className="text-xs">{repliedParticipants.text}</p>

                        <div className="mt-4 flex flex-row font-medium text-xs">
                            <p className="">I did not reply to {unrepliedParticipants.data.length} participant(s).</p>
                            {
                            dashboardType === 1 &&
                            <p className="pl-4 text-primaryBlue">Class average: {average.unreplied}</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
            }
            </div>
        </div>
    )
}

export default LadderPosted;