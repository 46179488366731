import { Image, Button, Popconfirm, message } from "antd"   
import Icon, { DeleteOutlined } from "@ant-design/icons"

import { Link } from "react-router-dom"
import DateHelper from "../../helpers/date"
import { useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL } from "../../services/server";
import LadderAnalyticsHelper from "../../helpers/analytics";

const AnalyticsSvg = () => (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.6526 15.5162C11.6266 15.5162 11.598 15.5162 11.572 15.5136C11.2158 15.4824 10.9116 15.2432 10.792 14.9052L8.97463 9.6558L8.18163 11.3276C8.03083 11.6448 7.71104 11.8476 7.36003 11.8476H4.72104C4.21924 11.8476 3.81104 11.4394 3.81104 10.9376C3.81104 10.4358 4.21924 10.0276 4.72104 10.0276H6.78544L8.28823 6.866C8.44684 6.5332 8.78743 6.3304 9.15923 6.3486C9.52843 6.3694 9.84823 6.6112 9.96783 6.9596L11.8502 12.3988L12.9266 10.4852C13.0878 10.1992 13.392 10.0224 13.7196 10.0224H16.421C16.9228 10.0224 17.331 10.4306 17.331 10.9324C17.331 11.4342 16.9228 11.8424 16.421 11.8424H14.25L12.443 15.0534C12.2818 15.342 11.9776 15.5188 11.65 15.5188L11.6526 15.5162Z" fill="white"/>
        <path d="M17.8276 20.9528H3.78761C2.13921 20.9528 0.797607 19.6112 0.797607 17.9628V3.9228C0.797607 2.2744 2.13921 0.9328 3.78761 0.9328H17.8276C19.476 0.9328 20.8176 2.2744 20.8176 3.9228V17.9628C20.8176 19.6112 19.476 20.9528 17.8276 20.9528ZM3.78761 2.7528C3.14281 2.7528 2.61761 3.278 2.61761 3.9228V17.9628C2.61761 18.6076 3.14281 19.1328 3.78761 19.1328H17.8276C18.4724 19.1328 18.9976 18.6076 18.9976 17.9628V3.9228C18.9976 3.278 18.4724 2.7528 17.8276 2.7528H3.78761Z" fill="white"/>
    </svg>
);
const AnalyticsIcon = (props) => <Icon component={AnalyticsSvg} {...props} />;
// const targetStudyUX = "https://testux.simplelittle.com/sit/"
const targetStudyUX = "https://ladder.rdc.nie.edu.sg/prd/"

const VideoCard = (props) => {
    const { user } = useSelector((state) => state.auth);

    const data = props.data;

    const handleDelete = (videoId) => {
        axios.delete(
            BASE_URL + 'video/' + videoId
        ).then((res) => {
            message.success('Video has been deleted')
            props.refreshData && props.refreshData()
        })
        .catch((err) => {
            message.error('Video cannot be deleted')
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        // let data = this.refs.data.getDOMNode().value;
        fetch("https://testux.simplelittle.com/sit/", {
            mode: 'no-cors',
            method: "POST",
            body: {
                "roomid": "2309140209234475557cb2360ed6e33b",
                "researchid": "0001AH",
                "name": "John De-Silver",
                "firsttask": "firsttask"
            }
        }).then(() => {
            console.log('success')
        })
    }
    const firstTask = (reseachId) => {
        const rId = reseachId.charAt(3).toLowerCase()
        return rId === "e" ? "English" : "Science"
    }

    const sendAnalytics = () => {
        let eventData = {
            type: 'Start_Experiment',
        }

        LadderAnalyticsHelper.sendEvent(eventData)
    }
    return(
        <div className="flex flex-col bg-blue-400 max-w-sm overflow-hidden rounded-md hover:bg-primaryBlue justify-center">
            <div className="flex relative text-white">
                <div
                    to={targetStudyUX}
                    className="cursor-pointer w-full py-4 px-12"
                    target="_blank" rel="noopener noreferrer">
                    <form action={targetStudyUX} method ='POST'
                          className="flex justify-center"
                          onSubmit={sendAnalytics}
                    >
                        <input type='hidden' name='roomid' value={data.room}/>
                        <input type ='hidden' name='researchid' value={user.rid}/>
                        <input type ='hidden' name='name' value={user.name} />
                        <input type ='hidden' name='firsttask' value={firstTask(user.rid)}/>
                        <br />
                        <input
                            className="cursor-pointer"
                            type='submit' name='submit'
                            value='Click here to start'
                        />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default VideoCard;
