import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router"

import ModalAnnotation from "../components/ModalAnnotation";
import ModalAnnotationAlert from "../components/ModalAnnotationAlert";
import ModalComment from "../components/ModalComment";
import VideoAnnotationPanel from "../components/VideoAnnotationPanel";

import * as video from "../store/video";

import { useParams } from "react-router-dom";
import DateHelper from "../helpers/date";
import {setVideo} from "../store/video";
import Layout from "./Layout";
import axios from "axios";
import {BASE_URL} from "../services/server";
import TreeHelper from "../helpers/tree";


const REQUEST_TIMER = 10000

export default function VideoAnnotationPage(){
    const [modalAnnotationShown, setModalAnnotationShown] = useState(false)
    const [modalCommentShown, setModalCommentShown] = useState(false)
    const [modalShare, setModalShare] = useState(false)
    const [modalSharedDetails, setModalSharedDetails] = useState(false)

    const [modalAnnotationAlertShown, setModalAnnotationAlertShown] = useState(false)
    const [commentParent, setCommentParent] = useState(0)
    const [commentData, setCommentData] = useState(null)
    const [annotationData, setAnnotationData] = useState(null)
    const [annotationId, setAnnotationId] = useState(-1)
    const [videoTags, setVideoTags] = useState([])
    const [isTimerShown, setIsTimerShown] = useState(false)
    const [timerSec, setTimerSec] = useState(0)

    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [startTime, setStartTime] = useState(Date.now());

    const [currentTime, setCurrentTime] = useState(-1)
    const { videoId } = useParams();
    const [videoUrl, setVideoUrl] = useState("")
    
    const [selectedVideo, setSelectedVideo] = useState(null);
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const { state } = useLocation();

    useEffect(() => {
        // if (state !== null) {
        //     const { new_video } = state;
        //
        //     if (new_video) setModalShare(true)
        // }
    }, [state])

     useEffect(() => {
         setIsTimerShown(false)
         setStartTime(Date.now())
    }, [])

    const getVideoData = (vId) => {
        axios.get(BASE_URL + 'ladder/videos/' + vId)
            .then((res) => {
                let comments = res.data.comments
                let flatComments = res.data.comments.slice(0)

                try {
                    comments = TreeHelper.arrayToTree(res.data.comments)
                }
                catch (err) {
                    console.log(err)
                }

                let timeline = null
                if (res.data.timeline.length > 0) {
                    const jsonTimeline = JSON.parse(res.data.timeline[0].state.replace(/'/g, '"'))
                    timeline = jsonTimeline
                }

                setSelectedVideo({
                    ...res.data,
                    comments,
                    flatComments,
                    timeline
                })
        })
    }

    useEffect(() => {
        // dispatch(video.get(videoId))
        getVideoData(videoId)
    }, [videoId])

    useEffect(() => {
        // const timer = setTimeout(() => dispatch(video.get(videoId)), REQUEST_TIMER);

        // return () => clearTimeout(timer);
    });

    const refreshVideoData = () => {
        getVideoData(videoId)
    }

    const handleReply = (e, annotationId, parentId) => {
        setAnnotationId(annotationId)
        setCommentParent(parentId)
        setCommentData(null)
        setModalCommentShown(true)
    }

    const handleEditComment = ( e, comment ) => {
        setModalCommentShown(true)
        setCommentData(comment)
    }

    const setTime = () => {
        const time = Date.now() - startTime

        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
      };

    useEffect(() => {
        /*
        const interval = setInterval(() => setTime(), 1000);

        return () => clearInterval(interval);
        */
      }, []);

    useEffect(() => {
        if ((selectedVideo !== null) && (selectedVideo.url !== null)) {
            if (selectedVideo.type === 'youtube') {
                setVideoUrl(selectedVideo.identifier)
            }
            else {
                if (videoUrl.length > 0) {
                    if (videoUrl !== selectedVideo.url) {
                        const newUrl = selectedVideo.url.split("?")
                        const prevUrl = videoUrl.split("?")
                        const expirySeconds = parseInt(videoUrl.split("Expires=")[1])
                        const expiryTime = DateHelper.epochToJsDate(expirySeconds)
                        const needRefresh = (DateHelper.getTimeBetweenDates(new Date(), expiryTime).seconds < 60)

                        if ((newUrl[0] !== prevUrl[0]) || needRefresh) setVideoUrl(selectedVideo.url)
                    }
                }
                else setVideoUrl(selectedVideo.url)
            }
        }
    }, [selectedVideo])

    const validateAnnotation = (ts) => {
        setCurrentTime(ts)
        setAnnotationId(-1)

        const isValid = selectedVideo.annotations.filter(ann => ann.time === ts).length === 0
        
        setAnnotationData(null)
        if (isValid) {
            setModalAnnotationShown(true)
            setVideoTags(selectedVideo.tags.map(t => ({ ...t, id: t.id.toString() })))
        }
        else {
            setModalAnnotationAlertShown(true)
        }
    }

    const handleEditAnnotation = (annData) => {
        setAnnotationId(-1)
        setAnnotationData(annData)
        setModalAnnotationShown(true)
    }

    const closeAnnotationModal = () => {
        getVideoData(videoId)
        setAnnotationData(null)
        setVideoTags([])
        setModalAnnotationShown(false)
    }

    const toggleTimerDisplay = (e) => {
        e.stopPropagation()
        setIsTimerShown(!isTimerShown)
    }

    return(
        <Layout className="pl-4">
            {/*
            <div className="flex flex-col justify-center items-center fixed bottom-0 right-10 bg-primaryBlue py-1 px-4 text-white rounded-t-md cursor-pointer"
                 onClick={toggleTimerDisplay}
            >
                <div className="text-xs">Click to { isTimerShown ? 'hide':'show' } timer</div>
                {
                isTimerShown &&
                <div className="text-lg">

                    {hours > 0 ? String(hours).padStart(2, '0') + ':' : ''}{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
                </div>
                }
            </div>
            */}

            {
                (selectedVideo === null) ?
                <div className="w-full pt-20 flex justify-center">Loading video</div> :
                <VideoAnnotationPanel
                    videoUrl={videoUrl}
                    data={selectedVideo}
                    openShare={() => {setModalShare(true)}}
                    openSharedDetails={() => {setModalSharedDetails(true)}}
                    hideShareButton={true}
                    isExperiment={true}
                    dashboardId={videoId}
                    refreshData={refreshVideoData}
                    handleAnnotate={validateAnnotation}
                    handleEditAnnotation={handleEditAnnotation}
                    handleReply={handleReply}
                    handleEditComment={handleEditComment}
                />
            }
            <ModalAnnotation
                show={modalAnnotationShown}
                annotationData={annotationData}
                videoId={selectedVideo ? selectedVideo.id : null}
                videoData={selectedVideo}
                videoTags={videoTags}
                ts={currentTime}
                onClose={closeAnnotationModal}
            />
            <ModalAnnotationAlert
                show={modalAnnotationAlertShown}
                ts={currentTime}
                onClose={() => { setModalAnnotationAlertShown(false) }}
            />
            <ModalComment
                show={modalCommentShown}
                videoId={selectedVideo ? selectedVideo.id : null}
                annotationId={annotationId}
                commentData={commentData}
                commentParent={commentParent}
                onClose={(refresh=false) => {
                    setModalCommentShown(false)
                    if (refresh) getVideoData(videoId)
                }}
            />
        </Layout>
    )
}