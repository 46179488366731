import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService, { ACCESS_TOKEN_NAME } from "../services/auth.services";

const accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);

export const register = createAsyncThunk(
    "auth/register",
    async ({ username, email, password }, thunkAPI) => {
        try {
            const response = await AuthService.register(username, email, password);
            thunkAPI.dispatch(setMessage(response.data.message));
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const login = createAsyncThunk(
    "auth/login",
    async ({ username, password }, thunkAPI) => {
    try {
        const data = await AuthService.login(username, password);
        return { user: data };
    } catch (error) {
        const message =
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString();
        
            thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
    }
  }
);

export const checkLogin = createAsyncThunk(
    "auth/user",
    async (_, thunkAPI) => {
    try {
        const data = await AuthService.checkLogin();
        return { user: data };
    } catch (error) {
        const message =
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString();
        thunkAPI.dispatch(setMessage(message));

        return thunkAPI.rejectWithValue();
    }
  }
);

export const getUser = createAsyncThunk(
    "auth/user",
    async (token, thunkAPI) => {
    try {
        const data = await AuthService.getUser(token);
        localStorage.setItem(ACCESS_TOKEN_NAME, token);

        return { user: data, accessToken: token };
    } catch (error) {
        const message =
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString();
        thunkAPI.dispatch(setMessage(message));

        return thunkAPI.rejectWithValue();
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
    await AuthService.logout();
});

const initialState = accessToken
    ? { isLoggedIn: true, accessToken, user: null }
    : { isLoggedIn: false, accessToken: null, user: null };

const authSlice = createSlice({
    name: "auth",
    initialState,
    extraReducers: {
        [register.fulfilled]: (state, action) => {
            state.isLoggedIn = false;
        },
        [register.rejected]: (state, action) => {
            state.isLoggedIn = false;
        },
        [login.fulfilled]: (state, action) => {
            state.isLoggedIn = true;
            state.accessToken = action.payload.access_token;
        },
        [login.rejected]: (state, action) => {
            state.isLoggedIn = false;
            state.accessToken = null;
            state.user = null
        },
        [logout.fulfilled]: (state, action) => {
            state.isLoggedIn = false;
            state.accessToken = null;
            state.user = null
        },
        [getUser.fulfilled]: (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload.user
            state.accessToken = action.payload.accessToken
        },
        [checkLogin.fulfilled]: (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload.user
        },
        [checkLogin.rejected]: (state, action) => {
            state.isLoggedIn = false;
            state.accessToken = null;
            state.user = null
        }
    },
});

const { reducer } = authSlice;
export default reducer;